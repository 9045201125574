import styled from 'styled-components';
import { fontSize, height, layout, space, width } from 'styled-utils';

export const ModalContent = styled.div<{ showBtnClose?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ showBtnClose }) =>
    showBtnClose
      ? space({ padding: ['123px 14px', '160px 79px'] })
      : space({ padding: ['48px 14px', '64px 79px'] })};
`;

export const ModalTitle = styled.div`
  ${fontSize({ fontSize: [20, 26] })};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  padding-bottom: 16px;
`;

export const ModalTextWrapper = styled.div`
  padding-bottom: 24px;
`;

export const ModalText = styled.p`
  ${layout({ width: [295, 369] })};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding-bottom: 8px;
`;

export const ModalButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${fontSize({ fontSize: [14, 20] })};
  color: ${({ theme }) => theme.colors.basic100};
  text-align: center;
  text-decoration: none;
  outline: none;
  border-radius: 35px;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.blue400};
  border-color: ${({ theme }) => theme.colors.blue400};
  ${fontSize({ fontSize: [14, 20] })};
  ${width({ width: ['151px', '224px'] })};
  ${height({ height: ['42px', '48px'] })};
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueHover};
  }
`;
