import React from 'react';
import { SanityHero } from 'components/Hero/Hero.sanity';
import { SanitySimpleText } from 'components/SimpleText/SanitySimpleText';
import { SanityMediaWithText } from 'components/MediaWithText/SanityMediaWithText';
import { SanityBlocksWithMedia } from 'components/BlocksWithMedia/SanityBlocksWithMedia';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { SanitySplitCarousel } from 'components/SplitSlider/SanitySplitSlider';
import { SanityStudentBeans } from 'components/StudentBeans/SanityStudentBeans';
import { SanityDevices } from 'components/Devices/SanityDevices';
import { SanityTestimonial } from 'components/Testimonial/SanityTestimonial';
import { SanityBeforeAndAfter } from 'components/BeforeAndAfter/SanityBeforeAndAfter';
import { SanityStickyCta } from 'components/StickyCta/SanityStickyCta';
import { StickyCtaShowHide } from 'components/StickyCta/StickyCtaShowHide';
import { SanityPricing } from 'components/Pricing/SanityPricing';
import { SanityRatingBlock } from 'components/RatingBlock/SanityRatingBlock';
import { SanityComparisonTable } from 'components/ComparisonTable/SanityComparisonTable';
import { SanityVideo } from 'components/Video/SanityVideo';
import { SanityQueryParamsConditionalBlocks } from 'components/QueryParamsConditionalBlocks/SanityQueryParamsConditionalBlocks';
import { Spacer } from 'components/Spacer/Spacer';
import { SanityHTML } from 'components/Html/SanityHTML';
import { SanityIconsList } from 'components/Common/IconsList/SanityIconsList';
import { SanityInfoCards } from 'components/Common/InfoCards/SanityInfoCards';
import { SanityFAQ } from 'components/Common/FAQ/SanityFAQ';
import { RichTextBlock2Sanity } from 'components/RichTextBlock2';
import { ButtonBlockSanity } from 'components/Common/ButtonBlock';
import { SanityDottedTimeline } from 'components/Timeline';

import {
  SanitySellPageSimpleText,
  SanitySellPageMediaWithText,
  SanitySellPageBlocksWithMedia,
  SanitySellPageDevices,
  SanitySellPageHtml,
  SanitySellPageComparisonTable,
  SanitySellPagePricing,
  SanitySellPageBeforeAfter, // Need to change when we update studio name
  SanitySellPageBeforeAndAfter,
  SanitySellPageVideo,
  SanitySellPageHeroBlock,
  SanityHeroSlider,
  SanitySellPageStickyCta,
  SanitySellPageStickyCtaShowHide,
  SanitySellPageQueryParamsConditionalBlocks,
  SanitySellPageSpacer,
  SanitySellPageFaqFragment,
  SanitySellPageTimeline,
  SellPageBySlugQuery,
  SanitySellPageInfoCardsBlockFragment,
  SanitySellPageIconListsBlockFragment,
  SanitySellPageMarketing,
  SanitySellPageStickyHeader,
  SanityProgramCarouselFragment,
  SanitySellPageRatingBlockFragment,
  SanityFollowUsFragment,
  SanityButtonBlockFragment,
  SanityRichTextBlock2Fragment,
  SanityComparisonChartFragment,
  SanitySplitSlider,
  SanityStudentBeansFragment,
} from 'graphql-types';
import { BlockType } from 'types';
import { renderBlockWithId } from 'helpers/block-with-id';
import { SanityMarketing } from 'components/Marketing/SanityMarketing';
import { SanityStickyHeader } from 'components/StickyHeader/SanityStickyHeader';
import { SanityProgramCarousel } from 'components/ProgramCarousel/ProgramCarousel.sanity';
import { SanityFollowUs } from 'components/FollowUs/FollowUs.sanity';
import { DeviceButtons } from 'components/DeviceButtons';
import { ComparisonChart } from 'components/Product/ComparisonChart/ComparisonChart';
import { SellPageContainer } from 'components/Common/Layout';

type BlocksArrayType = NonNullable<SellPageBySlugQuery['page']>['blocks'];

export const renderSellPageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case `SanitySellPageDevices`:
      return <SanityDevices key={id} fields={block as SanitySellPageDevices} />;
    case 'SanitySellPageSimpleText':
      return (
        <SanitySimpleText key={id} fields={block as SanitySellPageSimpleText} />
      );
    case 'SanitySellPageMediaWithText':
      return (
        <SanityMediaWithText
          key={id}
          fields={block as SanitySellPageMediaWithText}
        />
      );
    case 'SanitySellPageMarketing':
      return (
        <SanityMarketing key={id} fields={block as SanitySellPageMarketing} />
      );
    case 'SanitySellPageHeroBlock':
      return <SanityHero key={id} fields={block as SanitySellPageHeroBlock} />;
    case 'SanitySellPageStickyHeader':
      return (
        <SanityStickyHeader
          key={id}
          fields={block as SanitySellPageStickyHeader}
        />
      );
    case 'SanitySellPageHtml':
      return <SanityHTML fields={block as SanitySellPageHtml} key={id} />;
    case 'SanitySellPageComparisonTable':
      return (
        <SanityComparisonTable
          key={id}
          fields={block as SanitySellPageComparisonTable}
        />
      );
    case 'SanitySellPageBlocksWithMedia':
      return (
        <SanityBlocksWithMedia
          key={id}
          fields={block as SanitySellPageBlocksWithMedia}
        />
      );
    case 'SanitySellPagePricing':
      return <SanityPricing key={id} fields={block as SanitySellPagePricing} />;
    case 'SanitySellPageIconListsBlock':
      return (
        <SanityIconsList
          key={id}
          fields={block as SanitySellPageIconListsBlockFragment}
        />
      );
    case 'SanitySellPageInfoCardsBlock':
      return (
        <SanityInfoCards
          key={id}
          fields={block as SanitySellPageInfoCardsBlockFragment}
        />
      );
    case 'SanitySellPageBeforeAfter':
      return (
        <SanityTestimonial
          key={id}
          fields={block as SanitySellPageBeforeAfter}
        />
      );
    case 'SanitySellPageVideo':
      return <SanityVideo key={id} fields={block as SanitySellPageVideo} />;
    case 'SanityHeroSlider':
      return <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />;
    case 'SanitySplitSlider':
      return (
        <SanitySplitCarousel key={id} fields={block as SanitySplitSlider} />
      );
    case 'SanityStudentBeans':
      return (
        <SanityStudentBeans
          key={id}
          {...(block as SanityStudentBeansFragment)}
        />
      );
    case 'SanitySellPageStickyCta':
      return (
        <SanityStickyCta key={id} fields={block as SanitySellPageStickyCta} />
      );
    case 'SanitySellPageStickyCtaShowHide':
      return (
        <StickyCtaShowHide
          key={id}
          enableHide={(block as SanitySellPageStickyCtaShowHide)?.enableHide}
        />
      );
    case 'SanitySellPageQueryParamsConditionalBlocks':
      return (
        <SanityQueryParamsConditionalBlocks
          key={id}
          fields={block as SanitySellPageQueryParamsConditionalBlocks}
        />
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanitySellPageBeforeAndAfter':
      return (
        <SanityBeforeAndAfter
          key={id}
          fields={block as SanitySellPageBeforeAndAfter}
        />
      );
    case 'SanitySellPageFAQ':
      return <SanityFAQ key={id} {...(block as SanitySellPageFaqFragment)} />;
    case 'SanitySellPageTimeline':
      return (
        <SanityDottedTimeline
          key={id}
          fields={block as SanitySellPageTimeline}
        />
      );
    case 'SanityProgramCarousel':
      return (
        <SanityProgramCarousel
          key={id}
          fields={block as SanityProgramCarouselFragment}
        />
      );
    case 'SanitySellPageRatingBlock':
      return (
        <SanityRatingBlock
          key={id}
          fields={block as SanitySellPageRatingBlockFragment}
        />
      );
    case 'SanityFollowUs':
      return (
        <SanityFollowUs key={id} fields={block as SanityFollowUsFragment} />
      );
    case 'SanityRichTextBlock2':
      return (
        <RichTextBlock2Sanity
          key={id}
          fields={block as SanityRichTextBlock2Fragment}
          textContainer={SellPageContainer}
        />
      );
    case 'SanityButtonBlock':
      return (
        <ButtonBlockSanity
          key={id}
          fields={block as SanityButtonBlockFragment}
        />
      );
    case 'SanityComparisonChart':
      return (
        <ComparisonChart
          key={id}
          {...(block as SanityComparisonChartFragment)}
        />
      );
    case 'SanityDeviceButtons':
      return <DeviceButtons key={id} />;
    default:
      return `Unrecognized block type: ${type}`;
  }
}
