import { UpdateCartItem } from 'api/cart/cart.api.model';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { SanityQueryParamFragment } from 'graphql-types';

import {
  PricingBlockContentFragment,
  PricingContentItemFragment,
} from 'graphql-types-product';

export enum Type {
  product = 'product',
  plan = 'plan',
  layoutOne = 'Layout One',
  layoutTwo = 'Layout Two',
  layoutThree = 'Layout Three',
  physicalOne = 'Physical 1',
  physicalTwo = 'Physical 2',
  digitalOne = 'Digital 1',
}

export interface PricingViolatorProps {
  copy?: string;
  color?: string;
  mobilePosition?: string;
  desktopPosition?: string;
}

export interface PricingItemProps {
  id: string;
  loading?: boolean;
  productType?: ProductType;
  ctaStyling?: string;
  violatorSettings?: PricingViolatorProps;
  buyButtonOption?: string | 'cart' | 'checkout';
  addToCart: (item: UpdateCartItem) => Promise<void>;
  queryParams?: SanityQueryParamFragment[];
  content?: PricingContentItemFragment;
  productId?: string;
  productName?: string;
}

export enum ProductType {
  digital = 'Digital',
  physical = 'Physical',
}

export interface PricingViolatorProps {
  color?: string;
  mobilePosition?: string;
  desktopPosition?: string;
}

export interface LayoutProps {
  content?: PricingBlockContentFragment;
  textColor?: string;
  image?: IGatsbyImageData;
  pricingImageWidth?: number;
  items?: PricingItemProps[];
}
