import React from 'react';
import { Svg } from 'components/Common/Svg';

import { Container, Heading, IconList, Icon } from './FollowUs.styles';

import Facebook from 'svg/facebook.svg';
import Twitter from 'svg/twitter.svg';
import Instagram from 'svg/instagram.svg';

export const FollowUs: React.FC<{
  backgroundColor?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
}> = ({ backgroundColor, twitter, facebook, instagram }) => (
  <Container backgroundColor={backgroundColor}>
    <Heading>Follow Us On</Heading>
    <IconList>
      {facebook && (
        <Icon href={facebook}>
          <Svg icon={Facebook} />
        </Icon>
      )}
      {twitter && (
        <Icon href={twitter}>
          <Svg icon={Twitter} />
        </Icon>
      )}
      {instagram && (
        <Icon href={instagram}>
          <Svg icon={Instagram} />
        </Icon>
      )}
    </IconList>
  </Container>
);
