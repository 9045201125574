import React, { FC } from 'react';
import { SanitySellPageMarketing } from 'graphql-types';
import {
  MobileContainer,
  TopContainer,
  BottomContainer,
  MobileCtaContainer,
  MiddleContainer,
  MobileImageContainer,
} from '../Marketing.styles';
import { SanityRichText } from 'components/Common/SanityRichText';
import { Button } from 'components/Common/Button';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { getSpace, FullWidthMobileImage } from './helpers';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { getUrl } from 'helpers/LinkObject';

export const Mobile: FC<SanitySellPageMarketing> = ({
  mobileImage,
  mobileImageAltText,
  mobileImageAlignment,
  ctaText,
  ctaLink, // deprecated
  ctaLinkObject,
  ctaVariant,
  mobilePaddingTop,
  mobilePaddingBottom,
  _rawPreHeader,
  _rawHeader,
  _rawDescription,
}) => {
  const space = getSpace(mobilePaddingTop, mobilePaddingBottom);
  const MobileCta = () => {
    return (
      <MobileCtaContainer>
        <Button
          href={getUrl(ctaLinkObject, ctaLink)}
          variant={ctaVariant}
          onClick={awaitClick((e) =>
            logEvent('Sellpage: Click', { key: e.currentTarget.href })
          )}
        >
          {ctaText}
        </Button>
      </MobileCtaContainer>
    );
  };
  const PreHeader = () => {
    return (
      <>
        <SanityRichText blocks={_rawPreHeader} />
        <SanityRichText blocks={_rawHeader} />
      </>
    );
  };
  const FullContentAndCta = () => (
    <>
      <SanityRichText blocks={_rawPreHeader} />
      <SanityRichText blocks={_rawHeader} />
      <SanityRichText blocks={_rawDescription} />
      <MobileCta />
    </>
  );
  const DescriptionAndCta = () => {
    return (
      <>
        <SanityRichText blocks={_rawDescription} />
        <MobileCta />
      </>
    );
  };
  const FullWidthImage = () => {
    return (
      <FullWidthMobileImage
        mobileImage={mobileImage}
        mobileImageAltText={mobileImageAltText}
      />
    );
  };
  const InlineImage = () => {
    return (
      <MobileImageContainer>
        <GatsbyImage
          image={getSimplifiedImageData(mobileImage)}
          alt={mobileImageAltText || ''}
        />
      </MobileImageContainer>
    );
  };

  const Container: React.FC<{
    TopContent: React.ElementType;
    MiddleContent?: React.ElementType;
    BottomContent: React.ElementType;
  }> = ({ TopContent, MiddleContent, BottomContent }) => (
    <MobileContainer {...space}>
      <TopContainer>
        <TopContent />
      </TopContainer>
      {MiddleContent && (
        <MiddleContainer>
          <MiddleContent />
        </MiddleContainer>
      )}
      <BottomContainer>
        <BottomContent />
      </BottomContainer>
    </MobileContainer>
  );

  return (
    <>
      {mobileImageAlignment === 'top' && (
        <Container
          TopContent={FullWidthImage}
          BottomContent={FullContentAndCta}
        />
      )}
      {mobileImageAlignment === 'middle' && (
        <Container
          TopContent={PreHeader}
          MiddleContent={InlineImage}
          BottomContent={DescriptionAndCta}
        />
      )}
      {mobileImageAlignment === 'bottom' && (
        <Container
          TopContent={FullContentAndCta}
          BottomContent={FullWidthImage}
        />
      )}
    </>
  );
};
