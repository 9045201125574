import React from 'react';
import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  fontSize,
  lineHeight,
  space,
  right,
  height,
  minHeight,
  minWidth,
  SpaceProps,
} from 'styled-utils';
import ToggleIcon from './ToggleIcon.svg';
import { getLoginURL } from 'utils/url';
import { Button } from 'components/Common/Button';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { Svg } from 'components/Common/Svg';
import { LinkObjectFragment } from 'graphql-types';
import { getUrl } from 'helpers/LinkObject';

interface FAQItem {
  question?: string;
  answer?: string;
}

interface Props {
  titleText: string;
  titleTextColor?: string;
  backgroundColor?: string;
  questionBackgroundColor?: string;
  questionTextColor?: string;
  buttonColor?: string;
  enableCta: boolean;
  ctaText: string;
  ctaLink?: string; // deprecated
  ctaLinkObject?: LinkObjectFragment;
  questions: (FAQItem | undefined)[];
}

const Container = styled.div<ColorProps>`
  ${color};
  ${space({ pt: ['45px', '62px'], pb: ['50px', '71px'] })}
`;

const Title = styled.h2<ColorProps>`
  text-align: center;
  ${color};
  ${fontSize({ fontSize: [28, 48] })}
  ${lineHeight({ lineHeight: ['28px', '52px'] })}
  ${space({ mb: [25, 50], px: 30 })}
`;

const QuestionsWrapper = styled.div<SpaceProps>`
  ${space}
`;

interface QuestionProps extends FAQItem {
  questionBackgroundColor?: string;
  questionTextColor?: string;
  buttonColor?: string;
  index: number;
}

const QuestionContainer = styled.div`
  overflow: hidden;
  max-width: 815px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 9px;
`;

const QuestionTitle = styled.h3<ColorProps>`
  cursor: pointer;
  position: relative;
  ${color};
  ${space({ pl: [22, 30], pr: [55, 70], py: [10] })}
  ${fontSize({ fontSize: [16, 26] })}
  ${minHeight({ minHeight: [52, 75] })}
  line-height: 1.4;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
`;

const QuestionAnswerWrapper = styled.div<{ isOpen: boolean }>`
  max-height: 0px;
  opacity: 0;
  transition: all 300ms ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 1000px;
      opacity: 1;
    `}
`;

const QuestionAnswer = styled.div<ColorProps>`
  ${fontSize({ fontSize: [16] })}
  ${lineHeight({ lineHeight: ['20px', '22px'] })}
  ${color};
  ${space({ px: [22, 30], py: [15, 20] })}

  p + p {
    margin-top: 8px;
  }
`;

const ToggleIconContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: all 300ms;
  ${right({ right: [25, 35] })};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-50%) rotate(-45deg);
    `};
`;

const ToggleIconWrapper = styled.div<ColorProps>`
  ${color}
  ${fontSize({ fontSize: [20, 28] })}

  svg {
    display: block;
  }
`;

const CtaButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0 auto;
  ${fontSize({ fontSize: ['15px', '18px'] })}
  ${height({ height: ['46px', '60px'] })}
  ${lineHeight({ lineHeight: ['46px', '60px'] })}
  ${minWidth({ minWidth: ['193px', '312px'] })}
`;

export const Question: React.FC<QuestionProps> = (props) => {
  const [isOpen, toggleIsOpen] = React.useState(false);

  return (
    <QuestionContainer className={`Question Question-${props.index}`}>
      <div
        onClick={() => toggleIsOpen(!isOpen)}
        onKeyPress={() => toggleIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        <QuestionTitle
          className="QuestionTitle"
          color={props.questionTextColor}
          backgroundColor={props.questionBackgroundColor}
        >
          {props.question}
          <ToggleIconContainer isOpen={isOpen}>
            <ToggleIconWrapper color={props.buttonColor}>
              <Svg icon={ToggleIcon} useFontSize={true} />
            </ToggleIconWrapper>
          </ToggleIconContainer>
        </QuestionTitle>
      </div>
      <QuestionAnswerWrapper isOpen={isOpen} className="QuestionAnswerWrapper">
        <QuestionAnswer
          className="QuestionAnswer"
          color={props.questionTextColor}
          backgroundColor={props.questionBackgroundColor}
        >
          {props.answer?.split('\n').map((item, key) => {
            return <p key={key}>{item}</p>;
          })}
        </QuestionAnswer>
      </QuestionAnswerWrapper>
    </QuestionContainer>
  );
};

export const FAQ: React.FC<Props> = (props) => (
  <Container backgroundColor={props.backgroundColor} className="FAQ">
    <Title color={props.titleTextColor} className="Title">
      {props.titleText}
    </Title>
    <QuestionsWrapper
      className="Questions"
      mb={props.enableCta ? [40, 50] : []}
    >
      {props.questions.map((question, index) => (
        <Question
          index={index}
          key={index}
          {...question}
          questionBackgroundColor={props.questionBackgroundColor}
          questionTextColor={props.questionTextColor}
          buttonColor={props.buttonColor}
        />
      ))}
    </QuestionsWrapper>
    {props.enableCta && (
      <CtaButton
        className="CTA"
        href={
          props.ctaLinkObject?.page || props.ctaLinkObject?.url || props.ctaLink
            ? getUrl(props.ctaLinkObject, props.ctaLink)
            : getLoginURL()
        }
        variant="primary"
        onClick={awaitClick((e) =>
          logEvent('Sellpage: Click', { key: e.currentTarget.href })
        )}
      >
        {props.ctaText}
      </CtaButton>
    )}
  </Container>
);

FAQ.defaultProps = {
  titleTextColor: '#fff',
  backgroundColor: '#128FD0',
  questionBackgroundColor: '#067EBD',
  questionTextColor: '#fff',
  buttonColor: '#fff',
};
