import styled from 'styled-components';
import { display, fontSize, textAlign, width, space } from 'styled-system';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { ColorProps, SizeProps } from 'components/Timeline/model';

export const DottedTimelineWrapper = styled.div<ColorProps>`
  background-color: ${({ color, theme }) => color || theme.colors.basic200};
`;

export const DottedTimelineWrapperInner = styled.div`
  align-items: center;
  flex-direction: row-reverse;
  ${display({ display: ['block', 'block', 'flex'] })};
`;

export const Title = styled.div<ColorProps & SizeProps>`
  ${({ mobileSize, desktopSize }) =>
    fontSize({ fontSize: [mobileSize || '28px', desktopSize || '48px'] })}
  color: ${({ color, theme }) => color || theme.colors.blueNF1};
  margin-bottom: 10px;
`;

export const Image = styled(GatsbyImage)`
  ${width({ width: [1, 1, 0.6] })}
`;

export const Content = styled.div`
  padding: 30px;
  ${width({ width: [1, 1, 0.4] })}
  ${textAlign({ textAlign: ['center', 'left'] })}
`;

export const ButtonWrapper = styled.div<{ hideCtaOnMobile: boolean }>`
  ${space({ marginTop: 3 })}
  ${({ hideCtaOnMobile }) =>
    display({ display: [hideCtaOnMobile ? 'none' : 'flex', 'block'] })}
  justify-content: center;
`;
