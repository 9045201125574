import React from 'react';
import { SanitySellPageFaqFragment } from 'graphql-types';
import { FAQ } from './FAQ';

type Props = SanitySellPageFaqFragment;

export const SanityFAQ: React.FC<Props> = (props) => {
  return (
    <FAQ
      titleText={props.titleText || ''}
      titleTextColor={props.titleTextColor?.hex}
      backgroundColor={props.backgroundColor?.hex}
      questionBackgroundColor={props.questionBackgroundColor?.hex}
      questionTextColor={props.questionTextColor?.hex}
      buttonColor={props.buttonColor?.hex}
      enableCta={props.enableCta ?? false}
      ctaText={props.ctaText || ''}
      ctaLink={props.ctaLink}
      ctaLinkObject={props.ctaLinkObject}
      questions={props.questions || []}
    />
  );
};
