import styled from 'styled-components';
import {
  background,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  minHeight,
  maxWidth,
  space,
  width,
  BackgroundProps,
  ColorProps,
  LineHeightProps,
  FontSizeProps,
  FontWeightProps,
  MaxWidthProps,
  WidthProps,
} from 'styled-utils';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const StyledBeforeAndAfter = styled.div<BackgroundProps>`
  ${background}
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.h2<ColorProps & FontSizeProps>`
  ${color}
  ${fontSize({ fontSize: ['28px', '48px'] })}
  ${lineHeight({ lineHeight: ['32px', '52px'] })}
  ${space({ mt: ['45px', '62px'] })}
  ${space({ mb: ['20px', '35px'] })}
  width: 100%;
  text-align: center;
`;

export const Container = styled.div<MaxWidthProps>`
  width: 780px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Block = styled.div<WidthProps>`
  ${width}
  padding-left: 10px;
  padding-right: 10px;
`;

export const BlockContainer = styled.div<
  {
    boxShadow?: boolean;
  } & MaxWidthProps
>`
  ${maxWidth}
  background: ${({ theme }) => theme.colors.basic100};
  margin: 0 auto;
  padding: 5px;
  ${({ boxShadow }) =>
    boxShadow && `box-shadow: 0px 3px 15px rgba(0, 10, 131, 0.11);`}
`;

export const BlockImage = styled(GatsbyImage)`
  width: 100%;
  display: block;
  margin: 0 auto;
  ${space({ mb: ['5px', '12px'] })}
  ${minHeight({ minHeight: ['215px', '343px'] })}
`;

export const BlockText = styled.p<
  FontSizeProps & ColorProps & FontWeightProps & LineHeightProps
>`
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  text-align: center;
`;

export const Disclaimer = styled.p<
  {
    layout?: boolean;
  } & ColorProps
>`
  ${color}
  ${fontSize({ fontSize: ['14px', '16px'] })}
  line-height: 15px;
  z-index: 100;
  text-align: center;
  ${({ layout }) =>
    layout ? space({ mb: ['50px', '53px'] }) : space({ mb: ['0px', '0px'] })}
  ${({ layout }) =>
    layout ? space({ mt: ['28px', '60px'] }) : space({ mt: ['0px', '-50px'] })}
  ${({ layout }) => !layout && maxWidth({ maxWidth: [null, '197px'] })}
  ${({ layout }) => !layout && `margin: 0 auto;`}
`;
