/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { isMobile, useUserData } from '@bbnb/openfit-frontend-shared';
import { SellPageBySlugQuery, SeoMetadataFragment } from 'graphql-types';
import { logView } from 'helpers/Amplitude';
import { setProgramCode } from 'helpers/ProgramCode';
import { tagSellPageView } from 'helpers/Tealium';
import { GTMTagSellPageView } from 'helpers/GTM';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { renderSellPageBlock } from './SellPage.blocks';
import { Loading } from 'components/Common/Loading';
import { DigitalSubscriptionWarningModal } from 'components/DigitalSubscriptionWarningModal/DigitalSubscriptionWarningModal';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';
import {
  removePartnerSlug,
  setPartnerUrlSlug,
  initializePartnerCodeCookie,
} from 'helpers/Slug';

interface Props {
  data: SellPageBySlugQuery;
}

const SellPageTemplate: React.FC<Props> = (props) => {
  const post = props.data.page;
  const { loading, role } = useUserData();
  const mobileRedirect = isMobile && post!.mobileRedirectLink;
  const desktopRedirect = !isMobile && post!.desktopRedirectLink;
  const willRedirect =
    role === 'subscriber' && Boolean(mobileRedirect || desktopRedirect);

  useEffect(() => {
    if (loading || !willRedirect) {
      return;
    }

    if (mobileRedirect) {
      window.location.replace(mobileRedirect);
    } else if (desktopRedirect) {
      window.location.replace(desktopRedirect);
    }
  }, [loading, willRedirect, mobileRedirect, desktopRedirect]);

  useEffect(() => {
    const isPartner = post!.marketingType === 'Partner';
    if (isPartner) {
      setPartnerUrlSlug(post!.slug!.current);
    } else {
      removePartnerSlug();
    }
    setProgramCode(post!.programCode);
    logView({
      programId: post!.programCode,
      marketing_type: post!.marketingType,
    });
    initializePartnerCodeCookie();
    tagSellPageView();
    GTMTagSellPageView();
  }, []);

  const seoMetadata: SeoMetadataFragment = {
    pageTitle: post!.seoPageTitle,
    metaDescription: post!.seoMetaDescription,
    metaKeywords: post!.seoMetaKeywords,
    allowRobotIndexing: post!.seoAllowRobotIndexing,
    openGraphTitle: post!.seoOpenGraphTitle,
    openGraphDescription: post!.seoOpenGraphDescription,
    openGraphImage: {
      image: post!.seoOpenGraphImage,
    },
    canonicalUrl: post!.seoCanonicalUrl,
  };

  return (
    <>
      <SeoMetadata
        metaData={seoMetadata}
        slug={`plans/${post!.slug!.current!}`}
      />
      {willRedirect && <Loading />}
      {!willRedirect &&
        post!.blocks
          ?.map((block) => block && renderSellPageBlock(block))
          .map((block, index) => (
            <ImageLoadingWrapper
              key={index}
              eager={index < 4}
              children={block}
            />
          ))}
      <DigitalSubscriptionWarningModal />
    </>
  );
};

export default SellPageTemplate;

export const pageQuery = graphql`
  query SellPageBySlug($slug: String!) {
    page: sanitySellPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      name
      optimizelyType
      desktopRedirectLink
      mobileRedirectLink
      programCode
      marketingType
      hideHeader
      headerVisibility
      seoPageTitle
      seoMetaDescription
      seoMetaKeywords
      seoAllowRobotIndexing
      seoOpenGraphTitle
      seoOpenGraphDescription
      seoOpenGraphImage {
        asset {
          url
        }
      }
      seoCanonicalUrl
      blocks {
        ...SanitySellPageHeroBlock
        ...SanitySellPageSimpleText
        ...SanitySellPageMediaWithText
        ...SanitySellPageBlocksWithMedia
        ...SanitySellPageMarketing
        ...SanitySellPageDevices
        ...SanityHeroSlider
        ...SanitySplitSlider
        ...SanityStudentBeans
        ...SanitySellPageBeforeAfter
        ...SanitySellPageStickyCta
        ...SanitySellPageStickyHeader
        ...SanitySellPageStickyCtaShowHide
        ...SanitySellPagePricing
        ...SanitySellPageComparisonTable
        ...SanitySellPageVideo
        ...SanitySellPageQueryParamsConditionalBlocks
        ...SanitySellPageSpacer
        ...SanitySellPageHtml
        ...SanitySellPageBeforeAndAfter
        ...SanitySellPageIconListsBlock
        ...SanitySellPageFAQ
        ...SanitySellPageTimeline
        ...SanitySellPageInfoCardsBlock
        ...SanityProgramCarousel
        ...SanitySellPageRatingBlock
        ...SanityFollowUs
        ...SanityRichTextBlock2
        ...SanityButtonBlock
        ...SanityDeviceButtons
        ...SanityComparisonChart
      }
    }
  }
`;
