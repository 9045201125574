import React, { FC } from 'react';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { Maybe, SanityImage } from 'graphql-types';

interface Props {
  mobileImage?: Maybe<SanityImage>;
  mobileImageAltText?: Maybe<string>;
}

export const FullWidthMobileImage: FC<Props> = ({
  mobileImage,
  mobileImageAltText,
}) => {
  if (!mobileImage) return null;
  const image = getSimplifiedImageData(mobileImage);
  return (
    <GatsbyImage
      image={image}
      alt={mobileImageAltText || ''}
      objectFit="cover"
      objectPosition="50% 50%"
    />
  );
};

export const getSpace = (paddingTop?: number, paddingBottom?: number) => {
  const topPadding = paddingTop === 0 ? paddingTop : paddingTop || 50;
  const bottomPadding =
    paddingBottom === 0 ? paddingBottom : paddingBottom || 50;
  return {
    pt: [topPadding],
    pb: [bottomPadding],
  };
};
