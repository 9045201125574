import Cookies from 'js-cookie';

type Slug = string | undefined;

export const setPartnerUrlSlug = (slug: Slug): void => {
  if (!slug) return;
  Cookies.set('partnerUrlSlug', slug);
};

export const getPartnerSlug = (): Slug => {
  return Cookies.get('partnerUrlSlug');
};

export const removePartnerSlug = () => {
  return Cookies.remove('partnerUrlSlug');
};

export const initializePartnerCodeCookie = () => {
  const params = new URLSearchParams(window.location.search);
  const partnerCode = params.get('partner_code');

  if (!partnerCode) {
    return;
  }
  Cookies.set('partner_code', partnerCode);
};
