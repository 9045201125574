import styled from 'styled-components';
import {
  background,
  color,
  ColorProps,
  display,
  textAlign,
  fontSize,
  fontWeight,
  lineHeight,
  height,
  width,
  minWidth,
  maxWidth,
  WidthProps,
  MinWidthProps,
  space,
  BackgroundProps,
} from 'styled-utils';
import { justifyContent, JustifyContentProps } from 'styled-system';
import { StyledButton } from 'components/Common/Button/styles';
import { PhysicalPriceItem } from './PhysicalItemStyle';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const Physical2StyledPricing = styled.div<BackgroundProps>`
  display: flex;
  ${background}
  ${space({ my: ['0'], mx: ['0', '15px', '15px', 0] })}
`;

export const Physical2PricingImageWrapper = styled.div<
  WidthProps & MinWidthProps
>`
  min-width: 50%;
  ${minWidth}
  ${width}
  align-items: flex-start;
  ${display({ display: ['none', 'none', 'flex'] })};
  position: relative;
`;

export const Physical2PricingImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`;

export const Physical2PriceList = styled.div`
  ${width({ width: [1, 1, 1, '100%'] })}
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${PhysicalPriceItem} {
    ${space({ mt: ['30px', '30px', '30px', '40px'] })};
  }
`;

export const Physical2PricingHeader = styled.div<ColorProps>`
  ${color}
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  margin: auto;
  padding-top: 40px;
  ${space({ mb: ['20px', '0'], px: ['10px', '20px'] })}
  ${maxWidth({ maxWidth: ['92%', '100%'] })}
  ${fontSize({ fontSize: ['26px', '20px', '26px', '36px'] })}
`;

export const Physical2Description = styled.div<ColorProps>`
  & > div *,
  & > div div * {
    max-width: 525px;
    margin: auto;
    ${color}
    ${space({ p: ['5px 20px 20px 20px', '40px 0 40px 0'] })}
    ${textAlign({ textAlign: ['left', 'center', 'center', 'center'] })}
    ${fontSize({ fontSize: ['14px', '14px', '14px', '18px'] })}
    ${lineHeight({ lineHeight: ['18px', '18px', '18px'] })}
  }
`;

export const Physical2Disclaimer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.colors.basic100};
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  ${space({ mx: ['20px', '40px'] })}
  ${space({ mt: ['20px', '0px'] })}
  ${space({ mb: ['30px', '15px'] })}
`;
//

export const Physical2PriceItem = styled.a`
  display: block;
  text-decoration: none;
  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.default};
  }
  ${space({ mb: ['10px', '20px'] })}
  ${space({ mx: ['0', '40px'] })}
  border: ${({ theme }) => theme.colors.basic400} 1px solid;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.basic100};
`;

export const Physical2Body = styled.div`
  text-align: center;
  margin: 0 auto;
  position: relative;
  ${StyledButton} {
    margin: 0 auto;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: table;
    text-align: center;
    text-decoration: none;
    outline: none;
    border-radius: 35px;
    ${minWidth({ minWidth: ['144px'] })}
    ${fontSize({ fontSize: ['18px'] })}
    ${height({ height: ['34px'] })}
    ${lineHeight({ lineHeight: ['34px'] })}
  }
`;

export const SubHeader = styled.div`
  div p {
    ${space({ my: ['0px', '0px'] })}
    ${fontSize({ fontSize: ['12px', '20px'] })}
    ${lineHeight({ lineHeight: ['14px', '24px'] })}
  }
  div h2 {
    text-align: center;
    font-size: 26px;
    line-height: 30.16px;
  }
`;

export const Physical2Info = styled.div`
  div p {
    ${fontSize({ fontSize: ['12px', '14px'] })}
    ${lineHeight({ lineHeight: ['18px', '14px'] })}
    ${space({ mt: ['12px', '10px'] })}
    ${space({ mb: ['15px', '10px'] })}
  }
`;

export const Violator = styled.div<JustifyContentProps>`
  ${justifyContent}
  ${space({ pt: ['14px', '10px'] })}
  display: flex;
`;

export const ViolatorText = styled.span<BackgroundProps>`
  ${background}
  ${fontSize({ fontSize: ['12px', '18px'] })}
  ${lineHeight({ lineHeight: ['16px', '24px'] })}
  ${fontWeight({ fontWeight: ['bold', '900'] })}
  ${space({ py: ['2px', '3px'] })}
  ${space({ px: ['12px', '15px'] })}
`;
