import React from 'react';
import { Slider } from 'components/Common/Slider/Slider';
import { Maybe, SanitySellPageMediaWithText } from 'graphql-types';
import { SanityMediaWithText } from 'components/MediaWithText/SanityMediaWithText';

export interface Props {
  heroes?: Maybe<SanitySellPageMediaWithText>[];
  carouselInterval?: number;
  autoRotate?: boolean;
  withSlideProgressAnimation?: boolean;
}

export const SplitSlider = ({
  heroes,
  carouselInterval = 1,
  autoRotate = true,
}: Props): React.ReactElement | null => {
  const items = (heroes || []).filter(Boolean);
  if (!items.length || !items[0]) return null;

  if (items.length === 1) {
    return <SanityMediaWithText fields={items[0]} />;
  }

  const SplitSlides = () => (
    <Slider
      interval={carouselInterval * 1000}
      autoRotate={autoRotate}
      splitNavigation={true}
    >
      {items?.map(
        (hero: SanitySellPageMediaWithText, i: number): React.ReactElement => {
          return <SanityMediaWithText fields={hero} key={i} />;
        }
      )}
    </Slider>
  );

  return <SplitSlides />;
};
