import React from 'react';
import { graphql } from 'gatsby';
import Video from './Video';
import { SanitySellPageVideo } from 'graphql-types';

interface Props {
  fields: SanitySellPageVideo;
}

export const SanityVideo = ({ fields }: Props) => {
  const url = fields.video_file?.asset?.url || fields.video_link;
  return url ? <Video url={url} /> : null;
};

export const query = graphql`
  fragment SanitySellPageVideo on SanitySellPageVideo {
    _type
    __typename
    video_file {
      asset {
        url
      }
    }
    video_alt_text
    video_link
  }
`;
