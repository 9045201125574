import { tagCartAdd } from 'helpers/Tealium';
import { GTMTagCartAdd } from 'helpers/GTM';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { createCheckoutUrl } from 'utils/url';
import { ButtonVariant } from 'components/Common/Button/model';

import { PricingItemProps } from './model';

export type PricingLogOptions = {
  sku: string;
  rawPrice?: number;
  currencyCode?: string;
  isAddToCart: boolean;
  productId?: string;
  productName?: string;
};

const logPriceItemClick = async (options: PricingLogOptions): Promise<any> => {
  const {
    sku,
    rawPrice,
    currencyCode,
    isAddToCart,
    productName,
    productId,
  } = options;

  const logOptions = [
    {
      id: productId,
      name: productName,
      price: rawPrice,
      sku,
      currencyCode,
    },
  ];

  return Promise.all([
    GTMTagCartAdd(logOptions),
    tagCartAdd(logOptions),
    logEvent(
      isAddToCart
        ? 'Sellpage: Add To Cart Clicked'
        : 'Sellpage: Product selected',
      {
        programId: productId,
        planId: sku,
        price: rawPrice,
        currencyCode: currencyCode,
      }
    ),
  ]);
};

export const getLinkProps = ({
  addToCart,
  buyButtonOption,
  ctaStyling,
  content,
  productId,
  productName,
  queryParams,
}: PricingItemProps) => {
  const { rawPrice, currencyCode, sku = '', ctaText } = content || {};

  const couponCode =
    new URLSearchParams(window.location.search).get('coupon') || undefined;

  const isAddToCart = buyButtonOption === 'cart';

  const query: { [x: string]: any } =
    queryParams?.reduce<{ [x: string]: any }>((acc, param) => {
      if (!param?.name) return acc;
      acc[`${param.name}`] = param.value;
      return acc;
    }, {}) || {};

  const addToCartAction = isAddToCart
    ? () =>
        addToCart({
          id: sku,
          quantity: 1,
          coupon: couponCode,
        })
    : undefined;

  const onClick = awaitClick((event: React.MouseEvent<HTMLAnchorElement>) =>
    Promise.all([
      logPriceItemClick({
        sku,
        rawPrice,
        currencyCode,
        isAddToCart,
        productId,
        productName,
      }),
      addToCartAction && addToCartAction(),
    ])
  );

  const href = isAddToCart
    ? undefined
    : createCheckoutUrl({
        product: sku,
        ...query,
        ...(couponCode && { coupon: couponCode }),
      });

  const linkProps = {
    href,
    onClick,
    children: ctaText || 'Select',
    variant: (ctaStyling === 'primary'
      ? ctaStyling
      : 'primaryBorder') as ButtonVariant,
  };

  return linkProps;
};
