import * as React from 'react';

import { Button, TinyButton } from 'components/Common/Button';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { useIsBrowser } from 'utils/general';
import {
  Physical1StyledPricing,
  Physical1PricingImageWrapper,
  Physical1PricingImage,
  Physical1PriceList,
  Physical1PricingHeader,
  Physical1Body,
  Physical1Info,
  Physical1Price,
  Physical1PriceItem,
} from './Physical1Styles';
import { Disclaimer } from './Shared';
import { PricingItemProps, LayoutProps } from './model';
import { getLinkProps } from './helper';
import { SanityRichText } from 'components/Common/SanityRichText';

export const Physical1Item: React.FC<PricingItemProps> = (pricingItemProps) => {
  const { loading, content } = pricingItemProps;
  const { text, bottomText } = content || {};

  const isBrowser = useIsBrowser();
  if (loading || !isBrowser)
    return (
      <Physical1PriceItem>
        <LoadingPlaceHolder width={'100%'} height={'164px'} />
      </Physical1PriceItem>
    );

  const linkProps = getLinkProps(pricingItemProps);

  return (
    <>
      <Physical1PriceItem href={linkProps.href} onClick={linkProps.onClick}>
        <Physical1Body>
          {text && (
            <Physical1Price>
              <SanityRichText blocks={text} />
            </Physical1Price>
          )}
          <Button {...linkProps} />
          <TinyButton {...linkProps} variant="primaryUnderline" />
        </Physical1Body>
        {bottomText && (
          <Physical1Info>
            <SanityRichText blocks={bottomText} />
          </Physical1Info>
        )}
      </Physical1PriceItem>
    </>
  );
};

export const Physical1Layout: React.FC<LayoutProps> = ({
  content,
  textColor,
  image,
  pricingImageWidth,
  items,
}) => {
  return (
    <Physical1StyledPricing>
      {image && (
        <Physical1PricingImageWrapper
          minWidth={`${pricingImageWidth}%`}
          width={`${pricingImageWidth}%`}
        >
          <Physical1PricingImage image={image} alt={''} />
        </Physical1PricingImageWrapper>
      )}
      <Physical1PriceList>
        {content?.text && (
          <Physical1PricingHeader color={textColor}>
            <SanityRichText blocks={content?.text} />
          </Physical1PricingHeader>
        )}
        {items?.map((item: PricingItemProps) => (
          <Physical1Item key={item.id} {...item} />
        ))}
        {content?.disclaimer && (
          <Disclaimer color={textColor}>
            <SanityRichText blocks={content?.disclaimer} />
          </Disclaimer>
        )}
      </Physical1PriceList>
    </Physical1StyledPricing>
  );
};
