import styled, { css } from 'styled-components';
import {
  background,
  BackgroundProps,
  color,
  ColorProps,
  flexbox,
  layout,
  space,
  border,
  typography,
  position,
} from 'styled-system';

export const Header = styled.div<BackgroundProps>`
  text-align: center;
  ${background}
`;

export const Row = styled.div<
  { noBorder?: boolean } & ColorProps & BackgroundProps
>`
  display: flex;
  ${color}
  ${background}
  ${flexbox({ flexDirection: ['column', 'row'] })}
  ${space({ padding: ['0 10px', 0] })}
  ${({ noBorder, theme }) => {
    let desktopBorder = `2px solid ${theme.colors.gray2}`;
    if (noBorder) desktopBorder = 'none';
    return border({ borderBottom: ['none', desktopBorder] });
  }}
`;

export const MainCol = styled.div<{ isTitle?: boolean; isSubtitle?: boolean }>`
  flex: 1;
  align-items: center;
  font-weight: 800;
  ${typography({ fontSize: [16, 20], lineHeight: ['1.31', '1.28'] })}
  ${flexbox({ flexDirection: ['column', 'row'] })}
  ${({ isTitle }) => space({ padding: [isTitle ? 20 : '20px 10px', 24] })}
  ${({ isSubtitle }) =>
    layout({ display: [isSubtitle ? 'none' : 'flex', 'flex'] })}
`;

export const MainColTitle = styled(MainCol)`
  ${space({ padding: [20, 24] })}
`;

export const RightCol = styled.div`
  flex: 2;
  display: flex;
`;

export const Col = styled.div<
  {
    isTitle?: boolean;
    isSubtitle?: boolean;
    noBorder?: boolean;
  } & BackgroundProps
>`
  flex: 1;
  display: flex;
  position: relative;

  ${(props) => background({ background: ['none', props.background] })}

  ${typography({ fontSize: [16, 18], lineHeight: ['1.31', '1.28'] })}
  ${flexbox({ alignItems: ['start', 'center'] })}

  ${({ noBorder, isTitle, isSubtitle, theme }) => {
    let mobileBorder = `2px solid ${theme.colors.gray2}`;
    if (noBorder || isTitle || isSubtitle) mobileBorder = 'none';
    return border({ borderBottom: [mobileBorder, 'none'] });
  }}

  ${({ isTitle, isSubtitle }) => {
    let mobilePadding = '0 6px 20px 6px';
    if (isTitle) mobilePadding = '0 14px';
    if (isSubtitle) mobilePadding = '14px';
    return space({ padding: [mobilePadding, 24] });
  }}

  ${({ isSubtitle }) =>
    isSubtitle &&
    `
    &:first-child { ${css(space({ paddingRight: 24 }))} }
    &:last-child { ${css(space({ paddingLeft: 24 }))} }
  `}

  b {
    font-weight: bold;
  }
`;

export const VS = styled.div`
  background: ${({ theme }) => theme.colors.gray3};
  color: ${({ theme }) => theme.colors.basic100};
  font-weight: 900;
  line-height: 1;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 4px 4px;
  ${layout({
    width: [38, 66],
    height: [38, 66],
  })}
  ${typography({
    fontSize: [18, 26],
  })}
  ${position({
    top: [0, 44],
    right: [-19, -33],
  })}
`;

export const VSLine = styled.div`
  width: 2px;
  height: 60%;
  position: absolute;
  background: ${({ theme }) => theme.colors.gray2};
  right: -1px;
  ${layout({ display: ['block', 'none'] })}
`;

export const Title = styled.div<{ big?: boolean }>`
  font-weight: 900;
  line-height: 1.08;
  flex: 1;
  text-align: center;
  ${({ big }) => typography({ fontSize: [big ? 24 : 18, 36] })}
  ${space({ paddingTop: [0, 30] })}
  ${({ big }) =>
    flexbox({ alignSelf: [big ? 'center' : 'flex-start', 'flex-start'] })}
`;

export const Subtitle = styled.span`
  ${space({ padding: ['0 0 20px', 0] })}
  ${typography({
    textAlign: ['left', 'center'],
    fontSize: ['inherit', 20],
  })}
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
`;

export const MainImageContainer = styled.div`
  ${space({
    marginBottom: [20, 0],
    marginRight: [0, 10],
  })}
`;

export const Checkmark = styled.img<{ isCheckbox: boolean }>`
  ${({ isCheckbox }) =>
    layout({
      width: [isCheckbox ? 26 : 20, 34],
      height: [isCheckbox ? 26 : 20, 34],
    })}
  ${space({
    marginTop: ['4px', 0],
    marginRight: ['8px', 20],
  })}
`;

export const Footer = styled.div<BackgroundProps>`
  background: ${({ theme }) => theme.colors.gray4};
  font-size: 16px;
  line-height: 1.37;
  text-align: center;
  ${space({ padding: ['22px 12px', 30] })}
`;
