import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { SanitySellPageBeforeAfter } from 'graphql-types';
import Testimonial from './Testimonial';
import { ThemeContext } from 'styled-components';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanitySellPageBeforeAfter;
}

export const SanityTestimonial = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <Testimonial
      layout={fields.layout || 'horizontal'}
      title={fields.title}
      copyColor={fields.copyColor?.hex || theme.colors.default}
      copyVerticalAlignment={fields.copyVerticalAlignment || 'top'}
      copyDesktopHorizontalAlignment={
        fields.copyDesktopHorizontalAlignment || 'left'
      }
      items={[
        {
          quoteCopy: fields.quoteCopy1,
          iconImage: getSimplifiedImageData(fields.iconImage1),
          iconName: fields.iconName1,
          iconDescription: fields.iconDescription1,
        },
        {
          quoteCopy: fields.quoteCopy2,
          iconImage: getSimplifiedImageData(fields.iconImage2),
          iconName: fields.iconName2,
          iconDescription: fields.iconDescription2,
        },
        {
          quoteCopy: fields.quoteCopy3,
          iconImage: getSimplifiedImageData(fields.iconImage3),
          iconName: fields.iconName3,
          iconDescription: fields.iconDescription3,
        },
      ]}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageBeforeAfter on SanitySellPageBeforeAfter {
    _key
    _type
    __typename
    name
    layout
    title
    copyColor {
      hex
    }
    copyVerticalAlignment
    copyDesktopHorizontalAlignment
    quoteCopy1
    iconImage1 {
      asset {
        gatsbyImageData(width: 94, height: 94, layout: FIXED, placeholder: NONE)
      }
    }
    iconName1
    iconDescription1
    quoteCopy2
    iconImage2 {
      asset {
        gatsbyImageData(width: 94, height: 94, layout: FIXED, placeholder: NONE)
      }
    }
    iconName2
    iconDescription2
    quoteCopy3
    iconImage3 {
      asset {
        gatsbyImageData(width: 94, height: 94, layout: FIXED, placeholder: NONE)
      }
    }
    iconName3
    iconDescription3
  }
`;
