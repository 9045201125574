import React, { useState, useEffect } from 'react';
import { WarningModal } from 'components/WarningModal/WarningModal';
import { isLadder } from 'helpers/Ladder';
import {
  CartAdjustmentReason,
  DigitalSubscriptionWarningModalMessage,
} from 'api/cart/cart.api.model';

const POPUP_CONTENT: { [key in CartAdjustmentReason]: string } = {
  [CartAdjustmentReason.AlreadySubscribed]:
    'A digital subscription has been removed from your cart because our systems indicate that you are currently subscribed to the same product.',
  [CartAdjustmentReason.OnlyOneDigitalSubAllowed]:
    'A digital subscription already exists in your cart. Only one digital subscription can be purchased.',
  [CartAdjustmentReason.InvalidBundleReplacedWithDigital]:
    'Unfortunately, we currently do not offer supplements in your region. These items cannot be added to cart. Only the digital subscription was added to the cart.',
  [CartAdjustmentReason.NotAvailableForLocale]:
    'Currently, we do not support supplement purchases outside of the USA at this time.',
  [CartAdjustmentReason.InvalidProduct]:
    'This product does not appear to be available and was not added to the cart.',
};

export const DigitalSubscriptionWarningModal: React.FC = () => {
  const [cartAdjustmentReasons, setCartAdjustmentReasons] = useState<
    CartAdjustmentReason[]
  >();
  useEffect(() => {
    const digitalSubscriptionListener = ({ data }: MessageEvent) => {
      let parsedData: DigitalSubscriptionWarningModalMessage | null = null;
      try {
        parsedData = JSON.parse(data);
      } catch (e) {}
      if (parsedData?.type === 'DigitalSubLimit') {
        setCartAdjustmentReasons(parsedData.reasons || []);
      }
    };
    window.addEventListener('message', digitalSubscriptionListener);
    return () =>
      window.removeEventListener('message', digitalSubscriptionListener);
  }, []);

  if (!cartAdjustmentReasons) {
    return null;
  }

  const body = cartAdjustmentReasons.map((reason) => POPUP_CONTENT[reason]);

  return (
    <WarningModal
      title="Uh-oh"
      body={body}
      ctaText="Keep Shopping"
      ctaLink={isLadder() ? '/collections/products' : '/collections/shop-all'}
    />
  );
};
