import { hexToRgb, typography } from '@bbnb/openfit-frontend-shared';
import styled from 'styled-components';
import { color, ColorProps, space } from 'styled-utils';

export const Container = styled.div<ColorProps>`
  margin: auto;
  max-width: 1064px;
  ${color};
  ${space({ pt: '40px', pb: ['27px', '27px', 0] })}
`;

export const Heading = styled.h4`
  ${typography('heading4')};
  text-align: center;
  padding: 0 0 14px;
`;

export const IconList = styled.div`
  display: flex;
  margin: auto;
  max-width: 180px;
  justify-content: space-around;
`;

export const Icon = styled.a`
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 8px 5px 0 8px;
  background: ${({ theme }) => `rgba(${hexToRgb(theme.colors.blue200)}, 0.4)`};
  border-radius: 50%;
`;
