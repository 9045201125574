import * as React from 'react';
import { Button, TinyButton } from 'components/Common/Button';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { useIsBrowser } from 'utils/general';
import {
  Digital1StyledPricing,
  Digital1PricingImageWrapper,
  Digital1PricingImage,
  Digital1PriceList,
  Digital1PriceItem,
  Digital1PricingHeader,
  Digital1Body,
  SubHeader,
  Digital1Info,
  Violator,
  ViolatorText,
} from './Digital1Styles';
import { DigitalDisclaimer } from './Shared';
import { PricingItemProps, LayoutProps } from './model';
import { getLinkProps } from './helper';
import { SanityRichText } from 'components/Common/SanityRichText';

export const Digital1Item: React.FC<PricingItemProps> = (pricingItemProps) => {
  const { loading, violatorSettings, content } = pricingItemProps;
  const { text, bottomText, violator } = content || {};

  const isBrowser = useIsBrowser();
  if (loading || !isBrowser)
    return (
      <Digital1PriceItem>
        <LoadingPlaceHolder width={'100%'} height={'164px'} />
      </Digital1PriceItem>
    );

  const linkProps = getLinkProps(pricingItemProps);

  return (
    <>
      <Digital1PriceItem href={linkProps.href} onClick={linkProps.onClick}>
        <Digital1Body>
          {violator && (
            <Violator
              justifyContent={[
                violatorSettings?.mobilePosition === 'left'
                  ? 'flex-start'
                  : 'flex-end',
                violatorSettings?.desktopPosition === 'left'
                  ? 'flex-start'
                  : 'flex-end',
              ]}
            >
              <ViolatorText background={violatorSettings?.color}>
                {violator}
              </ViolatorText>
            </Violator>
          )}
          {text && (
            <SubHeader>
              <SanityRichText blocks={text} />
            </SubHeader>
          )}
          <Button {...linkProps} />
          <TinyButton {...linkProps} />
          {bottomText && (
            <Digital1Info>
              <SanityRichText blocks={bottomText} />
            </Digital1Info>
          )}
        </Digital1Body>
      </Digital1PriceItem>
    </>
  );
};

export const Digital1Layout: React.FC<LayoutProps> = ({
  content,
  textColor,
  image,
  pricingImageWidth,
  items,
}) => {
  return (
    <Digital1StyledPricing>
      {image && (
        <Digital1PricingImageWrapper
          minWidth={`${pricingImageWidth}%`}
          width={`${pricingImageWidth}%`}
        >
          <Digital1PricingImage image={image} alt={''} />
        </Digital1PricingImageWrapper>
      )}
      <Digital1PriceList>
        {content?.text && (
          <Digital1PricingHeader color={textColor}>
            <SanityRichText blocks={content.text} />
          </Digital1PricingHeader>
        )}
        {items?.map((item: PricingItemProps) => (
          <Digital1Item key={item.id} {...item} />
        ))}
        {content?.disclaimer && (
          <DigitalDisclaimer color={textColor}>
            <SanityRichText blocks={content.disclaimer} />
          </DigitalDisclaimer>
        )}
      </Digital1PriceList>
    </Digital1StyledPricing>
  );
};
