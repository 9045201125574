import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { SanitySellPageRatingBlockFragment } from 'graphql-types';

import { RatingBlock } from './RatingBlock';

interface Props {
  fields: SanitySellPageRatingBlockFragment;
}

export const SanityRatingBlock: FC<Props> = ({ fields }) => (
  <RatingBlock bgColor={fields.backgroundColor?.hex} rating={fields.rating} />
);

export const query = graphql`
  fragment SanitySellPageRatingBlock on SanitySellPageRatingBlock {
    _key
    _type
    __typename
    backgroundColor {
      hex
    }
    rating {
      ...SanityRatingColored
    }
  }
`;
