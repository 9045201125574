import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import {
  DottedTimeline,
  TimelineItem,
  SanityDottedTimelineProps,
} from 'components/Timeline';
import { ThemeContext } from 'styled-components';

export const SanityDottedTimeline = ({ fields }: SanityDottedTimelineProps) => {
  const theme = useContext(ThemeContext);
  return (
    <DottedTimeline
      image={fields.image}
      titleText={fields.titleText}
      mobileTitleFontSize={fields.mobileTitleFontSize ?? 28}
      desktopTitleFontSize={fields.desktopTitleFontSize ?? 38}
      titleFontColor={fields.titleFontColor?.hex || theme.colors.blueNF1}
      timelineColor={fields.timelineColor?.hex || theme.colors.blueNF1}
      backgroundColor={fields.backgroundColor?.hex || theme.colors.basic200}
      items={
        fields.items?.map(
          (item): TimelineItem => ({
            text: item?.text || '',
            color: item?.color?.hex || theme.colors.default,
          })
        ) || []
      }
      ctaDisplay={fields.ctaDisplay || 'hidden'}
      ctaText={fields.ctaText || ''}
      ctaLink={fields.ctaLink || ''}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageTimeline on SanitySellPageTimeline {
    _key
    _type
    __typename
    titleText
    mobileTitleFontSize
    desktopTitleFontSize
    titleFontColor {
      hex
    }
    image {
      asset {
        gatsbyImageData(width: 1170, placeholder: NONE)
      }
    }
    timelineColor {
      hex
    }
    backgroundColor {
      hex
    }
    items {
      text
      color {
        hex
      }
    }
    ctaDisplay
    ctaText
    ctaLink
  }
`;
