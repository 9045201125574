import styled from 'styled-components';

import {
  background,
  borderRadius,
  fontSize,
  color,
  lineHeight,
  space,
  textAlign,
  maxWidth,
  width,
  BackgroundProps,
  FontSizeProps,
  ColorProps,
  MaxWidthProps,
  SpaceProps,
  TextAlignProps,
  WidthProps,
  BorderProps,
} from 'styled-utils';

import { GatsbyImage } from 'components/Common/GatsbyImage';

export const StyledBlocksWithMedia = styled.div<BackgroundProps>`
  ${background}
`;

export const StyledBlocksWithMediaWrapper = styled.div<BackgroundProps>`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Block = styled.div<WidthProps & BackgroundProps & SpaceProps>`
  ${background}
  ${space}
  ${width}
  padding-left: 10px;
  padding-right: 10px;
`;

export const BlockContainer = styled.div`
  margin: 0 auto;
`;

export const BlockImage = styled(GatsbyImage)<BorderProps & MaxWidthProps>`
  ${borderRadius}
  ${maxWidth}
  width: 100%;
  display: block;
  height: auto;
  margin: 0 auto;
  ${space({ mb: ['25px', '30px'] })}
`;

export const BlockHeader = styled.h2<FontSizeProps & ColorProps>`
  ${fontSize}
  ${color}
  ${lineHeight({ lineHeight: ['24px', '32px'] })}
  font-weight: 800;
  text-align: center;
  padding-bottom: 15px;
`;

export const BlockText = styled.p<FontSizeProps & ColorProps & TextAlignProps>`
  ${fontSize}
  ${color}
  ${textAlign}
  ${lineHeight({ lineHeight: ['21px', '24px'] })}
`;
