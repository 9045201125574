import React, { FC } from 'react';
import { SanityRatingColoredFragment } from 'graphql-types';

import { RatingText } from 'components/RatingText';
import { Container } from './RatingBlock.styles';

type Props = {
  bgColor?: string;
  rating?: SanityRatingColoredFragment;
};

export const RatingBlock: FC<Props> = ({ bgColor, rating }) => {
  if (!rating) return null;
  return (
    <Container bgColor={bgColor}>
      <RatingText rating={rating} />
    </Container>
  );
};
