import React from 'react';
import {
  DotsWrapper,
  Item,
  Timeline,
  Body,
} from 'components/Timeline/TimelineDots.styles';
import { TimelineDotsProps } from './model';

export const TimelineDots: React.FC<TimelineDotsProps> = ({
  timelineColor,
  items = [],
}) => (
  <DotsWrapper>
    {items.map(({ text, color }) => (
      <Item key={text}>
        <Timeline color={timelineColor} />
        <Body color={color}>{text}</Body>
      </Item>
    ))}
  </DotsWrapper>
);
