import styled from 'styled-components';
import { background, BackgroundProps, space } from 'styled-system';

export const Container = styled.div<BackgroundProps>`
  ${background}
  display: flex;
  justify-content: center;
  align-items: center;
  ${space({ p: ['8px', '16px'] })};
`;
