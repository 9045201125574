import styled from 'styled-components';
import {
  background,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  space,
  BackgroundProps,
} from 'styled-utils';
import { justifyContent, JustifyContentProps } from 'styled-system';
import {
  StyledButton,
  StyledTinyButton,
} from 'components/Common/Button/styles';

export const DigitalPriceItem = styled.div`
  ${space({ mb: ['10px', '20px'] })}
  ${space({ mx: ['15px', '40px'] })}
  border: ${({ theme }) => theme.colors.basic400} 1px solid;
  border-radius: 4px;
`;

export const DigitalBody = styled.div`
  background-color: ${({ theme }) => theme.colors.basic100};
  text-align: center;
  margin: 0 auto;
  position: relative;

  ${StyledButton} {
    ${display({ display: ['none', 'none', 'table'] })};
    margin: 0 auto;
  }

  ${StyledTinyButton} {
    ${display({ display: ['block', 'block', 'none'] })};
    margin: 0 auto;
  }
`;

export const Header = styled.h2`
  ${space({ pt: ['17px', '23px'] })}
  ${fontSize({ fontSize: ['22px', '30px'] })}
  ${lineHeight({ lineHeight: ['18px', '28px'] })}
  color: ${({ theme }) => theme.colors.blue400};
  font-weight: 900;
  padding-bottom: 10px;
`;

export const SubHeader = styled.p`
  ${fontSize({ fontSize: ['12px', '20px'] })}
  ${lineHeight({ lineHeight: ['14px', '24px'] })}
  ${space({ pb: ['14px', '7px'] })}
`;

export const DigitalPrice = styled.h2`
  ${space({ pt: ['11px', '22px'] })}
  ${space({ pb: ['8px', '14px'] })}
  font-size: 30px;
  line-height: 28px;
  font-weight: 800;
`;

export const DigitalOriginalPrice = styled.span`
  color: ${({ theme }) => theme.colors.gray2};
  text-decoration: line-through;
  margin-right: 16px;
`;

export const DigitalInfo = styled.p`
  ${fontSize({ fontSize: ['12px', '18px'] })}
  ${lineHeight({ lineHeight: ['18px', '24px'] })}
  ${space({ pt: ['12px', '10px'] })}
  ${space({ pb: ['15px', '15px'] })}
`;

export const Violator = styled.div<JustifyContentProps>`
  ${justifyContent}
  ${space({ pt: ['14px', '10px'] })}
  display: flex;
`;

export const ViolatorText = styled.span<BackgroundProps>`
  ${background}
  ${fontSize({ fontSize: ['12px', '18px'] })}
  ${lineHeight({ lineHeight: ['16px', '24px'] })}
  ${fontWeight({ fontWeight: ['bold', '900'] })}
  ${space({ py: ['2px', '3px'] })}
  ${space({ px: ['12px', '15px'] })}
`;
