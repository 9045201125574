import React from 'react';
import { SanitySellPageQueryParamsConditionalBlocks } from 'graphql-types';
import { QueryParamsConditionalBlocks } from './QueryParamsConditionalBlocks';
import { BlockType } from 'types';

interface Props {
  fields: SanitySellPageQueryParamsConditionalBlocks;
}

type QueryParamsBlocks = {
  blocks: BlockType[];
  queryParams: string[];
}[];

export const SanityQueryParamsConditionalBlocks: React.FC<Props> = ({
  fields,
}) => {
  return (
    <QueryParamsConditionalBlocks blocks={fields.blocks as QueryParamsBlocks} />
  );
};
