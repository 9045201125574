import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import SimpleText from 'components/SimpleText';
import { SanitySellPageSimpleText } from 'graphql-types';
import { ThemeContext } from 'styled-components';

interface Props {
  fields: SanitySellPageSimpleText;
}

export const SanitySimpleText = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <SimpleText
      backgroundColor={fields.backgroundColor?.hex || theme.colors.basic100}
      desktopBlockBackgroundHeight={fields.desktopBlockBackgroundHeight ?? 100}
      desktopMainTextLine1Size={fields.desktopMainTextLine1Size ?? 36}
      desktopMainTextLine2Size={fields.desktopMainTextLine2Size ?? 36}
      desktopMainTextLocation={fields.desktopMainTextLocation}
      mainTextColor={fields.mainTextColor?.hex || theme.colors.default}
      mobileBlockBackgroundHeight={fields.mobileBlockBackgroundHeight ?? 100}
      mobileMainTextLine1Size={fields.mobileMainTextLine1Size ?? 24}
      mobileMainTextLine2Size={fields.mobileMainTextLine2Size ?? 24}
      mobileMainTextLocation={fields.mobileMainTextLocation}
      mainTextLine1={fields.mainTextLine1}
      mainTextLine2={fields.mainTextLine2}
    />
  );
};
export const query = graphql`
  fragment SanitySellPageSimpleText on SanitySellPageSimpleText {
    _key
    _type
    __typename
    name
    backgroundColor {
      hex
    }
    desktopBlockBackgroundHeight
    desktopMainTextLine1Size
    desktopMainTextLine2Size
    desktopMainTextLocation
    mainTextColor {
      hex
    }
    mobileBlockBackgroundHeight
    mobileMainTextLine1Size
    mobileMainTextLine2Size
    mobileMainTextLocation
    mainTextLine1
    mainTextLine2
  }
`;
