import { Modal, ModalProps, RedesignModalView } from 'components/Modal';
import { navigateToUrl } from 'helpers/General';
import React, { FC } from 'react';
import {
  ModalContent,
  ModalTitle,
  ModalTextWrapper,
  ModalText,
  ModalButton,
} from './WarningModal.styles';

export type WarningModalProps = {
  title?: string;
  body?: string | string[] | JSX.Element;
  ctaText?: string;
  ctaLink?: string;
  onCtaClick?: () => Promise<void>;
} & ModalProps;

export const WarningModal: FC<WarningModalProps> = ({
  title,
  body,
  ctaText,
  ctaLink,
  onOverlayClick,
  onCtaClick,
  ...props
}) => {
  const ctaClickHandler = async (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.persist();
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');

    if (onCtaClick) {
      await onCtaClick();
    }

    if (href) {
      navigateToUrl(href);
    }
  };

  return (
    <RedesignModalView>
      <Modal onOverlayClick={onOverlayClick} {...props}>
        <ModalContent showBtnClose={props.showBtnClose}>
          <ModalTitle>{title}</ModalTitle>
          <ModalTextWrapper>
            {Array.isArray(body) ? (
              <>
                {body.map((bodyItem, index) => (
                  <ModalText key={index}>{bodyItem}</ModalText>
                ))}
              </>
            ) : (
              <ModalText>{body}</ModalText>
            )}
          </ModalTextWrapper>
          {ctaLink && (
            <ModalButton href={ctaLink} onClick={ctaClickHandler}>
              {ctaText}
            </ModalButton>
          )}
        </ModalContent>
      </Modal>
    </RedesignModalView>
  );
};
