import React, { MouseEventHandler, FC } from 'react';
import { isIOS, isBrowser, isAndroid } from '@bbnb/openfit-frontend-shared';
import { useIsBrowser } from 'utils/general';
import { getLink } from 'helpers/RestAPI';
import { awaitClick, logEvent } from 'helpers/Amplitude';
import {
  GetTheApp,
  GetTheAppTitle,
  StyledDeviceBtn,
} from './DeviceButtons.styles';

type ButtonType = FC<{
  href: string;
  handleClick: (info: string) => MouseEventHandler;
}>;

const WebAppleStoreUrl =
  'https://apps.apple.com/us/app/openfit-fitness-nutrition/id1435537194';

const WebGooglePlayUrl =
  'https://play.google.com/store/apps/details?id=com.openfit.openfit';

export const DeviceButtons: FC = () => {
  const isBrowserNow = useIsBrowser();
  const [links, setLinks] = React.useState({
    appleStoreLink: WebAppleStoreUrl,
    googlePlayLink: WebGooglePlayUrl,
  });

  React.useEffect(() => {
    if (isAndroid || isIOS) {
      getLink().then((link) => {
        setLinks({
          appleStoreLink: link,
          googlePlayLink: link,
        });
      });
    }
  }, []);

  const onClick = (ctaText: string) =>
    awaitClick(() => logEvent('CTA Click', { cta_text: ctaText }));

  return (
    <GetTheApp>
      <GetTheAppTitle>Get the app</GetTheAppTitle>
      <StyledDeviceBtn key={isBrowserNow.toString()}>
        {isBrowser || isIOS ? (
          <AppStore href={links.appleStoreLink} handleClick={onClick} />
        ) : null}
        {isBrowser || isAndroid ? (
          <GooglePlay href={links.googlePlayLink} handleClick={onClick} />
        ) : null}
      </StyledDeviceBtn>
    </GetTheApp>
  );
};

const AppStore: ButtonType = ({ href, handleClick }) => (
  <a href={href} onClick={handleClick('Download on Apple App Store')}>
    <img
      src="https://cdn.prod.openfit.com/uploads/2020/08/26101101/AppStore.png"
      alt="Download on Apple App Store"
    />
  </a>
);

const GooglePlay: ButtonType = ({ href, handleClick }) => (
  <a href={href} onClick={handleClick('Get it on Google Play')}>
    <img
      src="https://cdn.prod.openfit.com/uploads/2020/07/23012740/GooglePlay.png"
      alt="Get it on Google Play"
    />
  </a>
);
