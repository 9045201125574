import * as React from 'react';
import {
  StyledBeforeAndAfter,
  Title,
  Container,
  Block,
  BlockContainer,
  BlockImage,
  BlockText,
  Disclaimer,
} from './styles';
import { BeforeAndAfterProps, ContainerProps } from './model';

const ContainerWrapper: React.FC<ContainerProps> = ({ layout, children }) => {
  return layout ? <Container>{children}</Container> : <>{children}</>;
};

export const BeforeAndAfter: React.FunctionComponent<BeforeAndAfterProps> = (
  props
): React.ReactElement => {
  const {
    backgroundColor,
    layout,
    title,
    titleColor,
    titleFontSize,
    mobileTitleFontSize,
    disclaimer,
    disclaimerColor,
    blocks,
  } = props;
  const width = blocks?.length ? 100 / blocks.length : 0;
  const defaultLayout = layout === 'Layout 1';
  return (
    <StyledBeforeAndAfter background={backgroundColor}>
      <ContainerWrapper layout={defaultLayout}>
        <Title
          color={titleColor}
          fontSize={[`${mobileTitleFontSize}px`, `${titleFontSize}px`]}
          dangerouslySetInnerHTML={{ __html: title || '' }}
        />
        {blocks?.filter(Boolean).map((block, index) => (
          <Block
            key={index}
            width={[defaultLayout ? `${width}%` : '100%', `${width}%`]}
          >
            <BlockContainer
              maxWidth={[null, defaultLayout ? `352px` : '489px']}
              boxShadow={defaultLayout}
            >
              {block.image && (
                <BlockImage image={block.image} alt={block.text1 || ''} />
              )}
              <BlockText
                color={block.textColor}
                fontSize={[`11px`, `18px`]}
                fontWeight={'bold'}
                lineHeight={[`17px`, `20px`]}
              >
                {block.text1}
              </BlockText>
              <BlockText
                color={block.textColor}
                fontSize={[`20px`, `30px`]}
                fontWeight={'bold'}
                lineHeight={[`20px`, `30px`]}
              >
                {block.text2}
              </BlockText>
              <BlockText
                color={block.textColor}
                fontSize={[`11px`, `18px`]}
                fontWeight={'normal'}
                lineHeight={[`17px`, `20px`]}
              >
                {block.text3}
              </BlockText>
            </BlockContainer>
          </Block>
        ))}
        <Disclaimer color={disclaimerColor} layout={defaultLayout}>
          {disclaimer}
        </Disclaimer>
      </ContainerWrapper>
    </StyledBeforeAndAfter>
  );
};
