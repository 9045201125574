import React from 'react';
import { queryParamsSatisfyAny } from 'utils/url';
import { BlockType } from 'types';
import { renderSellPageBlock } from 'templates/openfit/SellPage/SellPage.blocks';
import { useIsBrowser } from 'utils/general';

interface Props {
  blocks: {
    blocks: BlockType[];
    queryParams: string[];
  }[];
}

export const QueryParamsConditionalBlocks: React.FC<Props> = ({ blocks }) => {
  const isBrowser = useIsBrowser();

  // default block is a block that has empty queryParams
  const defaultBlocks = blocks.filter(
    (block) => !block.queryParams.every(Boolean)
  );

  // SSR default to be swaped in client side
  // based on query params
  if (!isBrowser) {
    return (
      // this prevents flashing when blocks are replaced
      // visibility hidden sets proper size of the block
      // without displaying its content
      // until we know which content to show
      <span style={{ visibility: 'hidden' }}>
        {defaultBlocks.map((block) => {
          return block.blocks.map(renderSellPageBlock);
        })}
      </span>
    );
  }

  const blocksWithMatchingQueryParams = blocks
    .filter((block) => block.queryParams.every(Boolean))
    .filter((block) => {
      const shouldRender = queryParamsSatisfyAny(
        window.location.search,
        block.queryParams
      );
      return shouldRender;
    });

  const shouldDisplayDefault = blocksWithMatchingQueryParams.length === 0;

  return (
    <span>
      {(shouldDisplayDefault
        ? defaultBlocks
        : blocksWithMatchingQueryParams
      ).map((block) => {
        return block.blocks.map(renderSellPageBlock);
      })}
    </span>
  );
};
