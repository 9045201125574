import React from 'react';
import { graphql } from 'gatsby';
import { useQuery } from 'urql';
import {
  SanityPriceOption,
  SanityQueryParamFragment,
  SanitySellPagePricing,
} from 'graphql-types';
import {
  LocalizedPricingContentQuery,
  LocalizedPricingContentQueryVariables,
} from 'graphql-types-product';
import { Pricing } from './Pricing';
import { PricingItemProps, ProductType, Type } from './model';
import { useCart } from 'hooks/use-cart';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { localizedPricingContentQuery } from './Pricing.queries';

export const SanityPricing = ({
  fields,
}: {
  fields: SanitySellPagePricing;
}) => {
  const [{ data, fetching }] = useQuery<
    LocalizedPricingContentQuery,
    LocalizedPricingContentQueryVariables
  >({
    query: localizedPricingContentQuery,
    variables: {
      id: fields.content?._id || '',
    },
  });

  const localizedContent = data?.priceBlockContentLocalized;

  const { addToCart } = useCart({
    lazyLoad: fields.pricing?.some(
      (pricing) => pricing?.buyButtonOption === 'cart'
    ),
  });

  return (
    <Pricing
      type={(fields.offerType as Type) || Type.digitalOne}
      backgroundColor={fields.backgroundColor?.hex || ''}
      textColor={fields.textColor?.hex || ''}
      content={localizedContent}
      image={getSimplifiedImageData(fields.image)}
      pricingImageWidth={fields.pricingImageWidth ?? 45}
      items={fields.pricing?.filter(Boolean).map(
        (priceOption: SanityPriceOption, index: number): PricingItemProps => {
          const localizedContentItem = localizedContent?.items?.[index];
          return {
            id: priceOption._key || String(index),
            loading: fetching,
            productType: priceOption.productType as ProductType,
            ctaStyling: priceOption.ctaStyling as string,
            buyButtonOption: priceOption.buyButtonOption,
            queryParams: priceOption.queryParams as SanityQueryParamFragment[],
            violatorSettings: {
              color: priceOption.violator?.color?.hex,
              desktopPosition: priceOption.violator?.desktopPosition,
              mobilePosition: priceOption.violator?.mobilePosition,
            },
            addToCart,
            content: localizedContentItem,
            productName: localizedContent?.productName,
            productId: localizedContent?.productId,
          };
        }
      )}
    />
  );
};

export const query = graphql`
  fragment SanityQueryParam on SanityQueryParam {
    name
    value
  }

  fragment SanitySellPagePricing on SanitySellPagePricing {
    _key
    _type
    __typename
    content {
      _id
    }
    backgroundColor {
      hex
    }
    offerType
    textColor {
      hex
    }
    image {
      asset {
        gatsbyImageData(width: 720, placeholder: NONE)
      }
    }
    pricingImageWidth
    pricing {
      productType
      buyButtonOption
      ctaStyling
      queryParams {
        ...SanityQueryParam
      }
      violator {
        color {
          hex
        }
        desktopPosition
        mobilePosition
      }
    }
  }
`;
