import React, { FC } from 'react';
import { Button } from 'components/Common/Button';

import { Container } from './ButtonBlock.styles';

type ButtonBlockProps = {
  backgroundColor?: string;
  cta?: {
    text?: string;
    ctaLink?: string;
    ctaVariant?: string;
  };
  mobileFullWidth: boolean;
};

export const ButtonBlock: FC<ButtonBlockProps> = ({
  backgroundColor,
  cta,
  mobileFullWidth,
}) => {
  return (
    <Container background={backgroundColor}>
      <Button fullWidthMobile={mobileFullWidth} href={cta?.ctaLink}>
        {cta?.text}
      </Button>
    </Container>
  );
};
