import styled from 'styled-components';
import {
  background,
  color,
  ColorProps,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  width,
  minWidth,
  maxWidth,
  WidthProps,
  MinWidthProps,
  space,
  BackgroundProps,
} from 'styled-utils';
import { justifyContent, JustifyContentProps } from 'styled-system';
import {
  StyledButton,
  StyledTinyButton,
} from 'components/Common/Button/styles';
import { DigitalPriceItem } from './DigitalItemStyle';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const Digital1StyledPricing = styled.div<BackgroundProps>`
  ${background}
  display: flex;
  ${space({ my: ['0'], mx: ['0', '15px', '15px', 0] })}
`;

export const Digital1PricingImageWrapper = styled.div<
  WidthProps & MinWidthProps
>`
  min-width: '36%';
  ${minWidth}
  ${width}
  align-items: flex-end;
  ${display({ display: ['none', 'none', 'flex'] })};
`;

export const Digital1PricingImage = styled(GatsbyImage)`
  width: 100%;
`;

export const Digital1PriceList = styled.div`
  ${width({ width: [1, 1, 1, '100%'] })}
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${DigitalPriceItem} {
    ${space({ mt: ['30px', '30px', '30px', '40px'] })};
  }
`;

export const Digital1PricingHeader = styled.div<ColorProps>`
  ${color}
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  margin: auto;
  padding-top: 40px;
  ${space({ mb: ['20px'] })}
  ${maxWidth({ maxWidth: ['72%'] })}
  ${fontSize({ fontSize: ['26px', '26px', '26px', '36px'] })}
  ${lineHeight({ lineHeight: ['28px', '28px', '28px', '48px'] })}
`;

export const Digital1PriceItem = styled.a`
  display: block;
  text-decoration: none;
  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.default};
  }
  ${space({ mb: ['10px', '20px'] })}
  ${space({ mx: ['0', '40px'] })}
  border: ${({ theme }) => theme.colors.basic400} 1px solid;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.basic100};
`;

export const Digital1Body = styled.div`
  text-align: center;
  margin: 0 auto;
  position: relative;

  ${StyledButton} {
    ${display({ display: ['none', 'none', 'table'] })};
    margin: 0 auto;
  }

  ${StyledTinyButton} {
    ${display({ display: ['block', 'block', 'none'] })};
    margin: 0 auto;
  }
`;

export const SubHeader = styled.div`
  ${space({ pt: ['17px', '23px'], px: '4px' })}
  div p {
    ${fontSize({ fontSize: ['12px', '20px'] })}
    ${lineHeight({ lineHeight: ['14px', '24px'] })}
    ${space({ mb: ['14px', '7px'] })}
    margin-top: 0;
  }
`;

export const Digital1Info = styled.div`
  div p {
    ${fontSize({ fontSize: ['12px', '18px'] })}
    ${lineHeight({ lineHeight: ['18px', '24px'] })}
    ${space({ mt: ['12px', '10px'] })}
    ${space({ mb: ['15px', '15px'] })}
  }
`;

export const Violator = styled.div<JustifyContentProps>`
  ${justifyContent}
  ${space({ pt: ['14px', '10px'] })}
  display: flex;
`;

export const ViolatorText = styled.span<BackgroundProps>`
  ${background}
  ${fontSize({ fontSize: ['12px', '18px'] })}
  ${lineHeight({ lineHeight: ['16px', '24px'] })}
  ${fontWeight({ fontWeight: ['bold', '900'] })}
  ${space({ py: ['2px', '3px'] })}
  ${space({ px: ['12px', '15px'] })}
`;
