import React from 'react';

import { TimelineDots } from './TimelineDots';
import { DottedTimelineProps } from './model';
import {
  DottedTimelineWrapper,
  DottedTimelineWrapperInner,
  Image,
  Content,
  Title,
  ButtonWrapper,
} from './DottedTimeline.styles';
import { SellPageContainer } from 'components/Common/Layout';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { Button } from 'components/Common/Button';

export const DottedTimeline: React.FC<DottedTimelineProps> = ({
  image,
  titleText,
  titleFontColor,
  mobileTitleFontSize,
  desktopTitleFontSize,
  backgroundColor,
  items,
  timelineColor,
  ctaDisplay,
  ctaLink,
  ctaText,
}) => {
  const showCta = ctaDisplay !== 'hidden' && ctaLink && ctaText;
  const hideCtaOnMobile = ctaDisplay === 'desktop';
  return (
    <DottedTimelineWrapper color={backgroundColor}>
      <SellPageContainer>
        <DottedTimelineWrapperInner>
          {image && (
            <Image image={getSimplifiedImageData(image)} alt="Timeline Image" />
          )}
          <Content>
            <Title
              mobileSize={mobileTitleFontSize}
              desktopSize={desktopTitleFontSize}
              color={titleFontColor}
            >
              {titleText}
            </Title>
            <TimelineDots {...{ items, timelineColor }} />
            {showCta ? (
              <ButtonWrapper hideCtaOnMobile={hideCtaOnMobile}>
                <Button variant="primary" href={ctaLink}>
                  {ctaText}
                </Button>
              </ButtonWrapper>
            ) : null}
          </Content>
        </DottedTimelineWrapperInner>
      </SellPageContainer>
    </DottedTimelineWrapper>
  );
};
