import Cookies from 'js-cookie';

type Code = string | undefined;

export const setProgramCode = (code: Code): void => {
  if (!code) return;
  Cookies.set('program_code', code, {
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });
};

export const getProgramCode = (): Code => {
  return Cookies.get('program_code');
};
