import styled from 'styled-components';
import { ColorProps } from 'components/Timeline/model';

export const Timeline = styled.div<ColorProps>`
  max-width: 9px;
  display: inline-flex;
  align-self: stretch;
  position: relative;
  width: 100%;

  :after {
    content: '';
    position: absolute;
    border-left: 2px solid
      ${({ color, theme }) => color || theme.colors.blueNF1};
    z-index: 1;
    left: calc(50% - 2px);
    height: 100%;
  }

  :before {
    content: '';
    position: absolute;
    border-radius: 100%;
    border: 4px solid ${({ color, theme }) => color || theme.colors.blueNF1};
    z-index: 1;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
  }
`;
export const Item = styled.div`
  display: flex;
`;

export const Body = styled.div<ColorProps>`
  margin: 15px 30px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 16px;
  text-align: left;
  color: ${({ color, theme }) => color || theme.colors.basic800};
`;

export const DotsWrapper = styled.div`
  ${Item}:first-of-type {
    ${Timeline}:after {
      top: 50%;
      height: 50%;
    }
  }
  ${Item}:last-of-type {
    ${Timeline}:after {
      height: 50%;
      bottom: 50%;
    }
  }
`;
