import React from 'react';
import { SanityRichText } from 'components/Common/SanityRichText';
import { SellPageContainer } from 'components/Common/Layout';

import {
  Header,
  Row,
  MainCol,
  RightCol,
  Title,
  Col,
  VS,
  VSLine,
  Subtitle,
  Content,
  MainImageContainer,
  Checkmark,
  Footer,
} from './styles';
import { ComparisonTableProps, Row as RowModel } from './model';

import xmark_file from './x.svg';
import checkmark_file from './check.svg';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const ComparisonTable = ({
  primaryColor,
  secondaryColor,
  header,
  headerBgColor,
  rows,
  footer,
  footerBgColor,
}: ComparisonTableProps) => (
  <SellPageContainer>
    <Header background={headerBgColor}>
      <Row noBorder color={primaryColor}>
        <MainCol isTitle>
          <Title big>{header.main.title}</Title>
        </MainCol>
        <RightCol>
          <Col isTitle>
            <Title>{header.first.title}</Title>
            <VS>
              <span>vs.</span>
            </VS>
          </Col>
          <Col isTitle>
            <Title>{header.second.title}</Title>
          </Col>
        </RightCol>
      </Row>
      <Row noBorder color={secondaryColor}>
        <MainCol isSubtitle />
        <RightCol>
          <Col isSubtitle>
            <Subtitle>{header.first.subtitle}</Subtitle>
            <VSLine />
          </Col>
          <Col isSubtitle>
            <Subtitle>{header.second.subtitle}</Subtitle>
          </Col>
        </RightCol>
      </Row>
    </Header>
    <Content>
      {rows?.map((row: RowModel, index) => (
        <Row
          key={index}
          noBorder={index === rows.length - 1}
          color={secondaryColor}
          background={row.bgColor}
        >
          <MainCol>
            <MainImageContainer>
              {row.main.image && (
                <GatsbyImage
                  image={row.main.image}
                  alt={row.main.title || ''}
                />
              )}
            </MainImageContainer>
            {row.main.title}
          </MainCol>
          <RightCol>
            <Col
              background={row.first.bgColor}
              noBorder={index === rows.length - 1}
            >
              <Checkmark
                isCheckbox={!!row.first.checkbox}
                src={row.first.checkbox ? checkmark_file.url : xmark_file.url}
              />
              {row.first.text && <SanityRichText blocks={row.first.text} />}
            </Col>
            <Col
              background={row.second.bgColor}
              noBorder={index === rows.length - 1}
            >
              <Checkmark
                isCheckbox={!!row.second.checkbox}
                src={row.second.checkbox ? checkmark_file.url : xmark_file.url}
              />
              {row.second.text && <SanityRichText blocks={row.second.text} />}
            </Col>
          </RightCol>
        </Row>
      ))}
    </Content>
    <Footer background={footerBgColor}>{footer}</Footer>
  </SellPageContainer>
);
