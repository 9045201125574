import { gql } from '@urql/core';

export const localizedPricingContentQuery = gql`
  fragment PricingContentItem on PriceBlockContentItem {
    sku
    additionalSkus
    text
    bottomText
    violator
    ctaText
    rawPrice
    currencyCode
  }

  fragment PricingBlockContent on PriceBlockContent {
    _id
    title
    text
    disclaimer
    productName
    productId
    items {
      ...PricingContentItem
    }
  }

  query LocalizedPricingContent($id: String!) {
    priceBlockContentLocalized(input: { id: $id }) {
      ...PricingBlockContent
    }
  }
`;
