import styled from 'styled-components';
import {
  space,
  color,
  ColorProps,
  background,
  BackgroundProps,
} from 'styled-utils';

export const Disclaimer = styled.div<ColorProps>`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.colors.basic100};
  ${color}
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  ${space({ mx: ['20px', '40px'] })}
  ${space({ mt: ['20px', '0px'] })}
${space({ mb: ['30px', '15px'] })}
`;

export const DigitalDisclaimer = styled.div<ColorProps>`
  ${color}
  padding-top: 20px;
  font-size: 12px;
  line-height: 16px;
  ${space({ mx: ['30px', '40px'] })}
  ${space({ mt: ['0px', '0px'] })}
  ${space({ mb: ['30px', '15px'] })}
`;

export const Wrapper = styled.div<BackgroundProps>`
  ${background}
`;
