import * as React from 'react';
import { Button } from 'components/Common/Button';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { useIsBrowser } from 'utils/general';
import {
  Physical2StyledPricing,
  Physical2PricingImageWrapper,
  Physical2PricingImage,
  Physical2PricingHeader,
  Physical2PriceList,
  Physical2PriceItem,
  Physical2Body,
  SubHeader,
  Physical2Info,
  Violator,
  ViolatorText,
} from './Physical2Styles';
import { Disclaimer } from './Shared';
import { PricingItemProps, LayoutProps } from './model';
import { getLinkProps } from './helper';
import { SanityRichText } from 'components/Common/SanityRichText';

export const Physical2Item: React.FC<PricingItemProps> = (pricingItemProps) => {
  const { loading, violatorSettings, content } = pricingItemProps;
  const { text, bottomText, violator } = content || {};

  const isBrowser = useIsBrowser();
  if (loading || !isBrowser)
    return (
      <Physical2PriceItem>
        <LoadingPlaceHolder width={'100%'} height={'164px'} />
      </Physical2PriceItem>
    );

  const linkProps = getLinkProps(pricingItemProps);

  return (
    <>
      <Physical2PriceItem href={linkProps.href} onClick={linkProps.onClick}>
        <Physical2Body>
          {violator && (
            <Violator
              justifyContent={[
                violatorSettings?.mobilePosition === 'left'
                  ? 'flex-start'
                  : 'flex-end',
                violatorSettings?.desktopPosition === 'left'
                  ? 'flex-start'
                  : 'flex-end',
              ]}
            >
              <ViolatorText background={violatorSettings?.color}>
                {violator}
              </ViolatorText>
            </Violator>
          )}
          {text && (
            <SubHeader>
              <SanityRichText blocks={text} />
            </SubHeader>
          )}
          <Button {...linkProps} />
          {bottomText && (
            <Physical2Info>
              <SanityRichText blocks={bottomText} />
            </Physical2Info>
          )}
        </Physical2Body>
      </Physical2PriceItem>
    </>
  );
};

export const Physical2Layout: React.FC<LayoutProps> = ({
  content,
  textColor,
  image,
  pricingImageWidth,
  items,
}) => {
  return (
    <Physical2StyledPricing>
      {image && (
        <Physical2PricingImageWrapper
          minWidth={`${pricingImageWidth}%`}
          width={`${pricingImageWidth}%`}
        >
          <Physical2PricingImage image={image} alt={''} />
        </Physical2PricingImageWrapper>
      )}
      <Physical2PriceList>
        {content?.text && (
          <Physical2PricingHeader color={textColor}>
            <SanityRichText blocks={content.text} />
          </Physical2PricingHeader>
        )}
        {items?.map((item: PricingItemProps) => (
          <Physical2Item key={item.id} {...item} />
        ))}
        {content?.disclaimer && (
          <Disclaimer color={textColor}>
            <SanityRichText blocks={content.disclaimer} />
          </Disclaimer>
        )}
      </Physical2PriceList>
    </Physical2StyledPricing>
  );
};
