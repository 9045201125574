import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { BeforeAndAfter } from './BeforeAndAfter';
import { BlockProps } from './model';
import {
  SanitySellPageBeforeAndAfter,
  SanitySellPageBeforeAndAfterBlock,
} from 'graphql-types';
import { ThemeContext } from 'styled-components';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanitySellPageBeforeAndAfter;
}

export const SanityBeforeAndAfter: React.FC<Props> = ({ fields }) => {
  const theme = useContext(ThemeContext);

  return (
    <BeforeAndAfter
      backgroundColor={fields.backgroundColor?.hex || theme.colors.basic200}
      layout={fields.layout}
      title={fields.title}
      titleColor={fields.titleColor?.hex || theme.colors.blueNF1}
      titleFontSize={fields.titleFontSize ?? 48}
      mobileTitleFontSize={fields.mobileTitleFontSize ?? 28}
      disclaimer={
        fields.disclaimer ||
        '†Results vary depending on starting point and effort. '
      }
      disclaimerColor={fields.disclaimerColor?.hex || theme.colors.basic600}
      blocks={fields.blocks?.map(
        (block: SanitySellPageBeforeAndAfterBlock): BlockProps => {
          return {
            image: getSimplifiedImageData(block?.image),
            text1: block?.text1,
            text2: block?.text2,
            text3: block?.text3,
            textColor: block?.textColor?.hex || theme.colors.blue400,
          };
        }
      )}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageBeforeAndAfter on SanitySellPageBeforeAndAfter {
    _key
    _type
    __typename
    name
    layout
    backgroundColor {
      hex
    }
    title
    titleColor {
      hex
    }
    titleFontSize
    mobileTitleFontSize
    disclaimer
    disclaimerColor {
      hex
    }
    blocks {
      image {
        asset {
          gatsbyImageData(width: 640, placeholder: NONE)
        }
      }
      text1
      text2
      text3
      textColor {
        hex
      }
    }
  }
`;
