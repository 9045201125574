import React, { useContext } from 'react';
import { SanitySellPageMarketing } from 'graphql-types';
import { ThemeContext } from 'styled-components';
import { Marketing } from './Marketing';

export const SanityMarketing: React.FC<{
  fields: SanitySellPageMarketing;
}> = ({ fields }) => {
  const theme = useContext(ThemeContext);
  return (
    <Marketing
      bgColor={fields.bgColor || { hex: theme.colors.basic100 }}
      _rawPreHeader={fields?._rawPreHeader}
      _rawHeader={fields?._rawHeader}
      _rawDescription={fields._rawDescription}
      desktopImage={fields.desktopImage}
      desktopImageAltText={fields.desktopImageAltText}
      desktopImageAlignment={fields.desktopImageAlignment}
      mobileImage={fields.mobileImage}
      mobileImageAltText={fields.mobileImageAltText}
      mobileImageAlignment={fields.mobileImageAlignment}
      ctaText={fields.ctaText}
      ctaLink={fields.ctaLink} // deprecated
      ctaLinkObject={fields.ctaLinkObject}
      ctaVariant={fields.ctaVariant}
      paddingTop={fields.paddingTop}
      paddingBottom={fields.paddingBottom}
      mobilePaddingTop={fields.mobilePaddingTop}
      mobilePaddingBottom={fields.paddingBottom}
    />
  );
};
