import styled from 'styled-components';
import {
  display,
  fontSize,
  height,
  lineHeight,
  minWidth,
  space,
  width,
} from 'styled-utils';
import {
  StyledButton,
  StyledTinyButton,
} from 'components/Common/Button/styles';

export const PhysicalPriceItem = styled.div`
  border: ${({ theme }) => theme.colors.basic400} 1px solid;
  border-radius: 4px;
  ${space({ pt: ['24px', '24px', '29px'], pb: ['8px'] })}
`;
export const PhysicalBody = styled.div`
  display: flex;
  width: 100%;
  justify-items: center;
  align-items: center;

  ${StyledButton} {
    margin-right: 25px;
    ${display({ display: ['none', 'none', 'table'] })};
  }

  ${StyledTinyButton} {
    ${display({ display: ['block', 'block', 'none'] })};
    margin-right: 15px;
  }
`;

export const PhysicalInfo = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.blue800};
  padding-top: 13px;
  ${fontSize({ fontSize: ['12px', '12px', '18px'] })};
  ${lineHeight({ lineHeight: ['18px', '18px', '24px'] })};
`;

export const PhysicalType = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  border-right: 1px solid ${({ theme }) => theme.colors.basic400};
  ${height({ height: ['32px', '32px', '57px'] })}
  ${width({ width: ['85px', '85px', '85px', '162px'] })};
  ${minWidth({ minWidth: ['85px', '85px', '85px', '162px'] })};
  ${fontSize({ fontSize: ['14px', '14px', '14px', '26px'] })};
`;

export const PhysicalPrice = styled.h2`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.blue400};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  margin: auto;
  ${space({ px: ['30px', '30px', '28px'] })};
  ${fontSize({ fontSize: ['30px', '30px', '30px', '52px'] })};
  ${lineHeight({ lineHeight: ['40px', '40px', '48px'] })};
`;
