import styled from 'styled-components';
import { MinHeightProps } from 'styled-utils';

export const Container = styled.div<MinHeightProps>`
  width: 100%;
  height: 720px;
  > iframe {
    height: 720px;
  }
`;
