import styled from 'styled-components';
import {
  background,
  color,
  ColorProps,
  display,
  fontSize,
  lineHeight,
  height,
  width,
  minWidth,
  maxWidth,
  WidthProps,
  MinWidthProps,
  space,
  BackgroundProps,
} from 'styled-utils';
import {
  StyledButton,
  StyledTinyButton,
} from 'components/Common/Button/styles';
import { PhysicalPriceItem } from './PhysicalItemStyle';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { standardTagMixin } from 'components/Common/Text';

export const Physical1StyledPricing = styled.div<BackgroundProps>`
  ${background}
  display: flex;
  ${space({ my: ['0'], mx: ['0', '15px', '15px', 0] })}
`;

export const Physical1PricingImageWrapper = styled.div<
  WidthProps & MinWidthProps
>`
  min-width: 36%;
  ${minWidth}
  ${width}
  align-items: flex-end;
  ${display({ display: ['none', 'none', 'flex'] })};
`;

export const Physical1PricingImage = styled(GatsbyImage)`
  width: 100%;
`;

export const Physical1PriceList = styled.div`
  ${width({ width: [1, 1, 1, '100%'] })}
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${PhysicalPriceItem} {
    ${space({ mt: ['30px', '30px', '30px', '40px'] })};
  }
`;

export const Physical1PricingHeader = styled.div<ColorProps>`
  ${color}
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  margin: auto;
  padding-top: 40px;
  ${space({ mb: ['20px'] })}
  ${maxWidth({ maxWidth: ['72%'] })}
  ${fontSize({ fontSize: ['26px', '26px', '26px', '36px'] })}
  ${lineHeight({ lineHeight: ['28px', '28px', '28px', '48px'] })}
`;

export const Physical1PriceItem = styled.a`
  display: block;
  text-decoration: none;
  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.default};
  }
  border: ${({ theme }) => theme.colors.basic400} 1px solid;
  border-radius: 4px;
  ${space({
    pt: ['24px', '24px', '29px'],
    pb: ['8px'],
    m: ['0', '1% auto 20px'],
    mb: ['20px', '15px'],
  })}
  ${width({ width: ['100%', '90%'] })}
`;
export const Physical1Body = styled.div`
  display: flex;
  width: 100%;
  justify-items: center;
  align-items: center;

  ${StyledButton} {
    margin-right: 25px;
    ${display({ display: ['none', 'none', 'table'] })};
  }

  ${StyledTinyButton} {
    ${display({ display: ['block', 'block', 'none'] })};
    margin-right: 15px;
  }
`;

export const Physical1Info = styled.div`
  div p {
    text-align: center;
    color: ${({ theme }) => theme.colors.blue800};
    padding-top: 13px;
    ${fontSize({ fontSize: ['12px', '12px', '18px'] })};
    ${lineHeight({ lineHeight: ['18px', '18px', '24px'] })};
    ${space({ my: ['0px', '0px'] })}
  }
`;

export const Physical1Type = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  border-right: 1px solid ${({ theme }) => theme.colors.basic400};
  ${height({ height: ['32px', '32px', '57px'] })}
  ${width({ width: ['85px', '85px', '85px', '162px'] })};
  ${minWidth({ minWidth: ['85px', '85px', '85px', '162px'] })};
  ${fontSize({ fontSize: ['14px', '14px', '14px', '26px'] })};
  ${standardTagMixin}
`;

export const Physical1Price = styled.h2<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  margin: auto;
  ${color}
  ${space({ px: ['30px', '30px', '28px'] })};
  ${fontSize({ fontSize: ['30px', '30px', '30px', '52px'] })};
  ${lineHeight({ lineHeight: ['40px', '40px', '48px'] })};
`;
