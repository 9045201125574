import { typography } from '@bbnb/openfit-frontend-shared';
import styled from 'styled-components';
import {
  space,
  borderRadius,
  height,
  justifyContent,
  textAlign,
  maxWidth,
} from 'styled-utils';

export const GetTheApp = styled.div`
  max-width: 1170px;
  ${space({
    mx: 'auto',
    px: [0, '105px'],
    py: ['24px', '40px'],
  })};
`;
export const GetTheAppTitle = styled.h4`
  padding-bottom: 17px;
  ${typography('heading4')};
  ${textAlign({ textAlign: ['center', 'center', 'left'] })};
`;

export const StyledDeviceBtn = styled.div`
  display: flex;
  ${maxWidth({ maxWidth: ['343px', '343px', '445px'] })};
  ${justifyContent({
    justifyContent: ['space-around', 'space-around', 'space-between'],
  })};
  ${space({ mx: ['auto', 'auto', 0] })}

  img {
    ${height({ height: ['48px', '48px', '64px'] })};
    ${borderRadius({ borderRadius: ['8px', '4px'] })};
  }
`;

export const AppleButton = styled.a``;
