import styled from 'styled-components';
import { color, ColorProps } from 'styled-utils';
import { device } from 'theme';
import { Layout, Alignment } from './model';

export const Testimonial = styled.div<ColorProps>`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.ivory};
  ${color};

  @media (min-width: ${device.small.width}) {
    padding: 70px 20px;
  }
`;

export const Title = styled.h2<Layout>`
  font-weight: 900;
  max-width: ${(props) => (props.layout === 'vertical' ? '720px' : '950px')};
  font-size: ${(props) => (props.layout === 'vertical' ? '23px' : '26px')};
  line-height: ${(props) => (props.layout === 'vertical' ? '1.217' : '1.076')};
  margin: 0 auto 30px;

  @media (min-width: ${device.small.width}) {
    font-size: 36px;
    line-height: ${(props) =>
      props.layout === 'vertical' ? '1.111' : '1.333'};
    text-align: center;
    margin-bottom: 54px;
  }
`;

export const Items = styled.div<Layout>`
  ${(props) =>
    props.layout === 'horizontal' &&
    `
      @media (min-width: ${device.small.width}) {
        display: flex;
        justify-content: space-around;
      }
    `}
`;

export const Item = styled.div<Layout>`
  margin-bottom: 30px;

  @media (min-width: ${device.small.width}) {
    width: 100%;
    margin: 0 3%;

    ${(props) =>
      props.layout === 'horizontal' &&
      `
        display: flex;
        flex-direction: column;
        width: 33%;
      `};

    ${(props) =>
      props.layout === 'vertical' &&
      `
        margin: 0 auto 60px;
        max-width: 600px;
      `};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Copy = styled.div<Alignment & Layout>`
  font-size: ${(props) => (props.layout === 'vertical' ? '18px' : '16px')};
  line-height: 1.3125;

  @media (min-width: ${device.small.width}) {
    flex: 1;
    display: flex;
    flex-direction: column;

    ${(props) =>
      props.copyVerticalAlignment === 'middle' && 'justify-content: center;'}
    ${(props) =>
      props.copyDesktopHorizontalAlignment === 'right' && 'text-align: right;'}
  }
`;

export const Bottom = styled.div<Layout>`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (min-width: ${device.small.width}) {
    margin-top: ${(props) => (props.layout === 'vertical' ? '16px' : '26px')};
  }
`;

export const ImageContainer = styled.div<Layout & { size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin-right: ${(props) => (props.layout === 'vertical' ? '15px' : '12px')};
`;

export const BottomText = styled.div`
  font-size: 16px;
`;

export const BottomName = styled.div`
  font-weight: bold;
  line-height: 1.33;
`;

export const BottomDescription = styled.div`
  font-size: 13px;
  line-height: 1.22;
  max-width: 260px;
  font-style: italic;
`;
