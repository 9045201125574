import * as React from 'react';
import { Physical1Layout } from './Physical1Layout';
import { Digital1Layout } from './Digital1Layout';
import { Physical2Layout } from './Physical2Layout';
import { Type, LayoutProps } from './model';
import { SellPageContainer } from 'components/Common/Layout';
import { Wrapper } from './Shared';

const getLayout = (type: Type, props: LayoutProps): React.ReactElement => {
  switch (type) {
    case Type.digitalOne:
      return <Digital1Layout {...props} />;
    case Type.physicalOne:
      return <Physical1Layout {...props} />;
    case Type.physicalTwo:
      return <Physical2Layout {...props} />;
    default:
      return <Physical2Layout {...props} />;
  }
};

interface PricingProps extends LayoutProps {
  backgroundColor: string;
  type: Type;
}

export const Pricing: React.FC<PricingProps> = (props) => {
  const { backgroundColor, type, ...layoutProps } = props;

  return (
    <Wrapper id="plans" background={backgroundColor}>
      <SellPageContainer>{getLayout(type, layoutProps)}</SellPageContainer>
    </Wrapper>
  );
};
