import React, { FC } from 'react';
import { SanitySellPageMarketing } from 'graphql-types';
import { Container } from './Marketing.styles';
import { Mobile, Desktop } from './Layouts';
import { SellPageContainer } from 'components/Common/Layout';

export const Marketing: FC<SanitySellPageMarketing> = (props) => {
  const { bgColor } = props;
  return (
    <Container background={bgColor?.hex}>
      <SellPageContainer>
        <Desktop {...props} />
        <Mobile {...props} />
      </SellPageContainer>
    </Container>
  );
};
