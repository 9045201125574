import React from 'react';
import { StudentBeans } from './StudentBeans';
import { graphql } from 'gatsby';
import { SanityStudentBeansFragment } from 'graphql-types';

export type SanityStudentBeansProps = SanityStudentBeansFragment;

export const SanityStudentBeans: React.FC<SanityStudentBeansProps> = (
  props
) => <StudentBeans {...props} />;

export const query = graphql`
  fragment SanityStudentBeans on SanityStudentBeans {
    _key
    _type
    __typename
    url
  }
`;
