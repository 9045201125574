import React from 'react';
import * as S from './styles';
import { SellPageContainer } from 'components/Common/Layout';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { TestimonialProps, TestimonialItemProps } from './model';
import profile from './profile.svg';

const TestimonialItem: React.FunctionComponent<TestimonialItemProps> = ({
  layout,
  quoteCopy,
  iconImage,
  iconName,
  iconDescription,
  copyVerticalAlignment,
  copyDesktopHorizontalAlignment,
}) => {
  const size = layout === 'vertical' ? 94 : 80;
  return (
    <S.Item layout={layout}>
      <S.Copy
        layout={layout}
        copyVerticalAlignment={copyVerticalAlignment}
        copyDesktopHorizontalAlignment={copyDesktopHorizontalAlignment}
      >
        {quoteCopy}
      </S.Copy>
      <S.Bottom layout={layout}>
        <S.ImageContainer layout={layout} size={`${size}px`}>
          <GatsbyImage
            image={
              iconImage || {
                layout: 'fullWidth',
                images: {
                  fallback: {
                    src: profile.url,
                    srcSet: '',
                  },
                },
                width: size,
                height: size,
              }
            }
            imgStyle={{
              width: size,
              height: size,
              borderRadius: '50%',
            }}
            alt={iconName || ''}
          />
        </S.ImageContainer>
        <S.BottomText>
          <S.BottomName>—{iconName}</S.BottomName>
          {iconDescription && (
            <S.BottomDescription>{iconDescription}</S.BottomDescription>
          )}
        </S.BottomText>
      </S.Bottom>
    </S.Item>
  );
};

const Testimonial: React.FunctionComponent<TestimonialProps> = ({
  copyColor,
  title,
  layout,
  items,
  copyVerticalAlignment,
  copyDesktopHorizontalAlignment,
}) => (
  <S.Testimonial color={copyColor || 'green800'}>
    <SellPageContainer>
      <S.Title layout={layout}>{title}</S.Title>
      <S.Items layout={layout}>
        {items.map(
          (item, index) =>
            item.quoteCopy && (
              <TestimonialItem
                key={`item_${index}`}
                copyVerticalAlignment={copyVerticalAlignment}
                copyDesktopHorizontalAlignment={copyDesktopHorizontalAlignment}
                layout={layout}
                {...item}
              />
            )
        )}
      </S.Items>
    </SellPageContainer>
  </S.Testimonial>
);

export default Testimonial;
