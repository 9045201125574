import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import {
  SanitySellPageComparisonTable,
  SanitySellPageComparisonTableRow,
} from 'graphql-types';
import { ComparisonTable } from './ComparisonTable';
import { ThemeContext } from 'styled-components';
import { Row } from './model';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanitySellPageComparisonTable;
}

export const SanityComparisonTable = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <ComparisonTable
      primaryColor={fields.primaryColor?.hex || theme.colors.default}
      secondaryColor={fields.secondaryColor?.hex || theme.colors.default}
      headerBgColor={fields.headerBgColor?.hex}
      footerBgColor={fields.footerBgColor?.hex || theme.colors.gray4}
      header={{
        main: {
          title: fields.title,
        },
        first: {
          title: fields.firstTitle,
          subtitle: fields.firstSubtitle,
        },
        second: {
          title: fields.secondTitle,
          subtitle: fields.secondSubtitle,
        },
      }}
      rows={fields.rows?.filter(Boolean).map(
        (row: SanitySellPageComparisonTableRow): Row => ({
          main: {
            title: row.title,
            image: getSimplifiedImageData(row.image),
          },
          first: {
            checkbox: row.firstCheckBox ?? true,
            text: row._rawFirstText,
            bgColor: row.firstBgColor?.hex || theme.colors.gray,
          },
          second: {
            checkbox: row.secondCheckBox ?? true,
            text: row._rawSecondText,
            bgColor: row.secondBgColor?.hex || theme.colors.gray,
          },
        })
      )}
      footer={fields.footer}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageComparisonTable on SanitySellPageComparisonTable {
    _key
    _type
    __typename
    name
    headerBgColor {
      hex
    }
    footerBgColor {
      hex
    }
    primaryColor {
      hex
    }
    secondaryColor {
      hex
    }
    title
    firstTitle
    secondTitle
    rows {
      title
      firstCheckBox
      firstBgColor {
        hex
      }
      secondCheckBox
      secondBgColor {
        hex
      }
      image {
        asset {
          gatsbyImageData(width: 62, height: 62, placeholder: NONE)
        }
      }
      _rawFirstText
      _rawSecondText
    }
    firstSubtitle
    secondSubtitle
    footer
  }
`;
