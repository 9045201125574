import React from 'react';
import { SanityStudentBeansProps } from './SanityStudentBeans';
import { Container } from './StudentBeans.styles';
import { useIsBrowser } from 'utils/general';

export const StudentBeans: React.FC<SanityStudentBeansProps> = ({ url }) => {
  const isBrowser = useIsBrowser();

  if (!isBrowser) {
    return null;
  }

  return (
    <Container className="iframe-container">
      <script
        data-iframe={url}
        data-load="connect"
        id="stb_root"
        src="https://cdn.studentbeans.com/third-party/all.js"
      ></script>
    </Container>
  );
};
