import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { BlocksWithMedia } from './BlocksWithMedia';
import { BlockProps } from './model';
import {
  SanitySellPageBlocksWithMedia,
  SanitySellPageBlocksWithMediaBlock,
} from 'graphql-types';
import { ResponsiveValue } from 'styled-system';
import * as CSS from 'csstype';
import { ThemeContext } from 'styled-components';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanitySellPageBlocksWithMedia;
}

export const SanityBlocksWithMedia = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <BlocksWithMedia
      backgroundColor={fields.backgroundColor?.hex || theme.colors.default}
      imageMaxWidth={fields.imageMaxWidth ?? 160}
      headerFontSize={fields.headerFontSize ?? 36}
      mobileHeaderFontSize={fields.mobileHeaderFontSize ?? 28}
      headerColor={fields.headerColor?.hex || theme.colors.default}
      textFontSize={fields.textFontSize ?? 16}
      mobileTextFontSize={fields.mobileTextFontSize ?? 16}
      textAlign={fields.textAlign as ResponsiveValue<CSS.Property.TextAlign>}
      textColor={fields.textColor?.hex}
      marginTop={fields.marginTop}
      marginBottom={fields.marginBottom}
      mobileMarginTop={fields.mobileMarginTop}
      mobileMarginBottom={fields.mobileMarginBottom}
      roundedCorners={fields.roundedCorners ?? false}
      blocks={fields.blocks?.map(
        (block: SanitySellPageBlocksWithMediaBlock): BlockProps => {
          return {
            image: getSimplifiedImageData(block?.image),
            header: block?.header,
            text: block?.text,
          };
        }
      )}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageBlocksWithMedia on SanitySellPageBlocksWithMedia {
    _key
    _type
    __typename
    name
    backgroundColor {
      hex
    }
    imageMaxWidth
    headerFontSize
    mobileHeaderFontSize
    headerColor {
      hex
    }
    textFontSize
    mobileTextFontSize
    textAlign
    textColor {
      hex
    }
    marginTop
    marginBottom
    mobileMarginTop
    mobileMarginBottom
    roundedCorners
    blocks {
      image {
        asset {
          gatsbyImageData(width: 640, placeholder: NONE)
        }
      }
      header
      text
    }
  }
`;
