import React, { FC } from 'react';
import { SanitySellPageMarketing } from 'graphql-types';
import {
  DesktopImageContainer,
  DesktopContainer,
  LeftContainer,
  RightContainer,
  CtaContainer,
} from '../Marketing.styles';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { SanityRichText } from 'components/Common/SanityRichText';
import { Button } from 'components/Common/Button';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { getSpace } from './helpers';
import { getUrl } from 'helpers/LinkObject';

export const Desktop: FC<SanitySellPageMarketing> = ({
  desktopImage,
  desktopImageAltText,
  desktopImageAlignment,
  ctaText,
  ctaLink, // deprecated
  ctaLinkObject,
  ctaVariant,
  paddingTop,
  paddingBottom,
  _rawPreHeader,
  _rawHeader,
  _rawDescription,
}) => {
  const space = getSpace(paddingTop, paddingBottom);
  const ContentContainer = () => (
    <>
      <SanityRichText blocks={_rawPreHeader} />
      <SanityRichText blocks={_rawHeader} />
      <SanityRichText blocks={_rawDescription} />
      <CtaContainer>
        <Button
          href={getUrl(ctaLinkObject, ctaLink)}
          variant={ctaVariant}
          onClick={awaitClick((e) =>
            logEvent('Sellpage: Click', { key: e.currentTarget.href })
          )}
        >
          {ctaText}
        </Button>
      </CtaContainer>
    </>
  );
  const DestkopImageComponent: FC<SanitySellPageMarketing> = () => {
    return (
      <DesktopImageContainer>
        <GatsbyImage
          image={getSimplifiedImageData(desktopImage)}
          alt={desktopImageAltText || ''}
        />
      </DesktopImageContainer>
    );
  };
  const Container: React.FC<{
    LeftContent: React.ElementType;
    RightContent: React.ElementType;
  }> = ({ LeftContent, RightContent }) => (
    <DesktopContainer {...space}>
      <LeftContainer>
        <LeftContent />
      </LeftContainer>
      <RightContainer>
        <RightContent />
      </RightContainer>
    </DesktopContainer>
  );
  return (
    <>
      {desktopImageAlignment === 'left' && (
        <Container
          LeftContent={DestkopImageComponent}
          RightContent={ContentContainer}
        />
      )}
      {desktopImageAlignment === 'right' && (
        <Container
          LeftContent={ContentContainer}
          RightContent={DestkopImageComponent}
        />
      )}
    </>
  );
};
