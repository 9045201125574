import * as React from 'react';
import {
  StyledBlocksWithMedia,
  StyledBlocksWithMediaWrapper,
  Block,
  BlockContainer,
  BlockImage,
  BlockHeader,
  BlockText,
} from './styles';

import { BlocksWithMediaProps } from './model';
import { SellPageContainer } from 'components/Common/Layout';

export const BlocksWithMedia: React.FunctionComponent<BlocksWithMediaProps> = (
  props: BlocksWithMediaProps
): React.ReactElement => {
  const {
    backgroundColor,
    imageMaxWidth,
    headerFontSize,
    mobileHeaderFontSize,
    headerColor,
    textFontSize,
    mobileTextFontSize,
    textAlign,
    textColor,
    marginTop,
    marginBottom,
    mobileMarginTop,
    mobileMarginBottom,
    roundedCorners,
    blocks,
  } = props;

  const width = blocks?.length ? 100 / blocks.length : 0;
  const borderRadius = roundedCorners ? '15px' : '0px';
  const align = textAlign || 'left';

  return (
    <StyledBlocksWithMedia background={backgroundColor}>
      <SellPageContainer>
        <StyledBlocksWithMediaWrapper>
          {blocks?.filter(Boolean).map((block, index) => (
            <Block
              width={['100%', `${width}%`]}
              background={backgroundColor}
              mt={[`${mobileMarginTop}px`, `${marginTop}px`]}
              mb={[`${mobileMarginBottom}px`, `${marginBottom}px`]}
              key={index}
            >
              <BlockContainer>
                {block.image && (
                  <BlockImage
                    alt={block.header || ''}
                    image={block.image}
                    borderRadius={borderRadius}
                    maxWidth={`${imageMaxWidth}px`}
                  />
                )}
                <BlockHeader
                  color={headerColor}
                  fontSize={[
                    `${mobileHeaderFontSize}px`,
                    `${headerFontSize}px`,
                  ]}
                >
                  {block.header}
                </BlockHeader>
                <BlockText
                  color={textColor}
                  textAlign={align}
                  fontSize={[`${mobileTextFontSize}px`, `${textFontSize}px`]}
                >
                  {block.text}
                </BlockText>
              </BlockContainer>
            </Block>
          ))}
        </StyledBlocksWithMediaWrapper>
      </SellPageContainer>
    </StyledBlocksWithMedia>
  );
};
