import React from 'react';
import { SanityFollowUsFragment } from 'graphql-types';
import { FollowUs } from './FollowUs';
import { isLadder } from 'helpers/Ladder';

interface FollowUsProps {
  fields: SanityFollowUsFragment;
}

const links = isLadder()
  ? {
      facebook: 'https://www.facebook.com/laddersport',
      twitter: 'https://twitter.com/laddersport',
      instagram: 'https://www.instagram.com/laddersport',
    }
  : {
      facebook: 'https://www.facebook.com/openfit',
      twitter: 'https://twitter.com/MyOpenfit',
      instagram: 'https://www.instagram.com/myopenfit',
    };

export const SanityFollowUs = ({ fields }: FollowUsProps) => (
  <FollowUs backgroundColor={fields.backgroundColor?.hex} {...links} />
);
