import React from 'react';
import { graphql } from 'gatsby';
import { SplitSlider } from './SplitSlider';
import { SanitySplitSlider } from 'graphql-types';

interface Props {
  fields: SanitySplitSlider;
}

export const SanitySplitCarousel = ({ fields }: Props) => {
  return <SplitSlider {...fields} />;
};

export const query = graphql`
  fragment SanitySplitSlider on SanitySplitSlider {
    _key
    _type
    __typename
    carouselInterval
    autoRotate
    withSlideProgressAnimation
    heroes {
      ...SanitySellPageMediaWithText
    }
  }
`;
