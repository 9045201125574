import styled, { css } from 'styled-components';
import {
  background,
  height,
  HeightProps,
  space,
  SpaceProps,
  BackgroundProps,
  display,
} from 'styled-utils';

export const fullMobileImageMixin = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  div[data-gatsby-image-wrapper] {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const Container = styled.div<BackgroundProps & HeightProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${height};
  ${background}
`;

export const DesktopImageContainer = styled.div`
  ${display({ display: ['none', 'block'] })};
`;

export const MobileImageContainer = styled.div`
  ${display({ display: ['block', 'none'] })};
`;

export const DesktopContainer = styled.div<SpaceProps>`
  display: flex;
  flex-direction: row;
  ${display({ display: ['none', 'flex'] })};
  ${space};
  .textSmall strong {
    font-weight: 900;
  }
`;

export const MobileContainer = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${display({ display: ['flex', 'none'] })};
  ${space};
  .textSmall strong {
    font-weight: 900;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  button {
    max-width: 267px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  button {
    max-width: 267px;
  }
`;

export const TopContainer = styled.div`
  ${fullMobileImageMixin}
  padding-bottom: 20px;
`;

export const BottomContainer = styled.div`
  ${fullMobileImageMixin}
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
`;

export const MobileCtaContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 25px 10% 0 10%;
`;

export const DesktopCtaContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 10px 0 10px 0%;
`;

export const CtaContainer = styled.div`
  display: block;
`;
