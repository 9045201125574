import React from 'react';
import { Helmet } from 'react-helmet';
import { VideoContainer } from './styles';

import { VideoProps } from './model';

const Video = ({ url }: VideoProps) => (
  <>
    <Helmet>
      <meta property="og:video" content={url} />
    </Helmet>
    <VideoContainer controls>
      <source src={url} />
    </VideoContainer>
  </>
);

export default Video;
