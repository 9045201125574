import React from 'react';
import { ButtonBlock } from './ButtonBlock';
import { SanityButtonBlockFragment } from 'graphql-types';

type SanityButtonBlockProps = { fields: SanityButtonBlockFragment };

export const ButtonBlockSanity: React.FC<SanityButtonBlockProps> = ({
  fields,
}) => {
  return (
    <ButtonBlock
      backgroundColor={fields.backgroundColor?.hex}
      cta={fields.cta}
      mobileFullWidth={!!fields.mobileFullWidth}
    />
  );
};
